

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Post } from '@/store/modules/posts/types'
import postVue from '@/components/ui/post/post.vue'

@Component({ components: { postVue } })
export default class carouselItemsVue extends Vue
{
	@Prop() readonly galleryId?: string
	get post() { return this.galleryId ? this.$modules.Posts.posts[this.galleryId] : null }

	mounted()
	{
		this.$watch(() => this.galleryId, this.pullPost, { immediate: true })
	}

	async pullPost() { if (this.galleryId) await this.$modules.Posts.rule('user_gallery').first(this.galleryId) }
}
