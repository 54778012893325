
























import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { PromiseHelper } from '@/libs/helpers/Promise'

@Component
export default class stickyVue extends Vue
{
	@Prop({ default: 0 }) readonly offset?: string

	@Ref('placeholder') readonly placeholderRef!: HTMLDivElement
	@Ref('sticky') readonly stickyRef!: HTMLDivElement
	@Ref('container') readonly containerRef!: HTMLDivElement

	created() { window.addEventListener('resize', this.recalculateSizes); window.addEventListener('scroll', this.recalculateSizes) }
	destroyed() { window.removeEventListener('resize', this.recalculateSizes); window.removeEventListener('scroll', this.recalculateSizes) }

	mounted() { this.recalculateSizes() }

	isActive = true
	activated() { this.isActive = true; this.recalculateSizes() }
	deactivated() { this.isActive = false }

	async recalculateSizes()
	{
		if (!this.isActive) return

		const placeholderRect = this.placeholderRef.getBoundingClientRect()
		this.stickyRef.style.width = `${placeholderRect.width}px`

		await PromiseHelper.Sleep()

		const stickyRect = (this.stickyRef.querySelector('[sticky-target]') ?? this.stickyRef).getBoundingClientRect()
		this.placeholderRef.style.height = `${stickyRect.height}px`

		await PromiseHelper.Sleep()

		const containerRect = this.containerRef.getBoundingClientRect()
		this.containerRef.style.height = `calc(100% - ${scrollY + containerRect.y}px)`
		this.containerRef.style.width = `calc(100% - ${scrollX + containerRect.x}px)`
	}
}
