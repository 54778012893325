
















import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import responsiveVue from './responsive.vue'
import { VImg, VCard, VSheet } from 'vuetify/lib'
import { router } from '@/plugins/router'

@Component({ components: { responsiveVue } })
export default class textViewerVue extends Vue
{
	@Prop() readonly html?: string
	output: Array<string | object> = []

	@Ref('container') readonly containerRef!: Vue

	mounted()
	{
		this.updateHtml()
	}

	@Watch('html') updateHtml()
	{
		if (!this.html) return
		this.output = []
		this.containerRef.$el.innerHTML = this.$sanitize(this.html, {
			allowedTags: ['a', 'b', 'strong', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'ul', 'li'],
			allowedAttributes:
			{
				'a': ['*'],
				'img': ['*']
			}
		}).replaceAll(new RegExp('\\[(.*?)\\]', 'g'), '')

		const images = this.containerRef.$el.querySelectorAll('img')
		const links = this.containerRef.$el.querySelectorAll('a')

		for (const image of images)
		{
			const url = new URL(image.src)
			const src = url.hostname === location.hostname ? `//cdn.1petci.com${url.pathname}` : url.href

			const instance = new VImg({
				propsData: {
					src: `${src}?m=720`,
					lazySrc: `${src}?m=50`,
					width: '100%',
					height: image.height,
					maxHeight: '360px',
					alt: image.alt,
					contain: true
				}
			})
			instance.$mount()
			const el = instance.$el as HTMLElement
			image.parentElement?.insertBefore(el, image)
			image.remove()
		}

		for (const link of links)
		{
			try 
			{
				Array.from(link.attributes).forEach((att) => att.name !== 'href' && link.removeAttribute(att.name))
				if (link.hash)
				{
					const json = decodeURIComponent(link.hash.substring(1))
					const resolved = router.resolve(JSON.parse(json))
					link.href = resolved.href
					link.title = ''
					link.addEventListener('click', (event) =>
					{
						event.preventDefault()
						router.push(resolved.location)
					})
				}
				else if (link.hostname === location.hostname)
				{
					link.addEventListener('click', (event) =>
					{
						event.preventDefault()
						router.push(link.pathname)
					})
				}
			}
			catch (ex)
			{
				console.error(link, ex)
			}
		}
	}
}
