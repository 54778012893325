import { Dictionary } from '@/libs/collections/Dictionary';
import { ObjectHelper } from '@/libs/helpers/Object';
import { Api } from '@/plugins/api';
import { modules } from '@/store/classModules';
import { defineModule, VuexClassModule } from '@/modules/vuex-class-module';
import Vue from 'vue';
import { Post, PostFilter, PostListArguments, PostOrder, Rule, RuleFieldOption, RulePermissions } from './types';

export const Posts = defineModule(new class extends VuexClassModule 
{
    posts: Dictionary<Post<any> | null> = {}

    getRule(name: string, ignoreInherit: boolean = false) { return Object.freeze(this._getRule(name, ignoreInherit)) }
    private _getRule(name: string, ignoreInherit: boolean, inherited: boolean = false): Rule
    {
        let rule = ObjectHelper.mergeDeep({}, ((require(`@/../public/.subdomains/api/rules/${name}`)))) as Rule

        if (!ignoreInherit && rule.inherit)
        {
            for (let inherit of rule.inherit)
            {
                rule = ObjectHelper.mergeDeep({}, this._getRule(inherit, false, true), rule)
            }
        }

        for (const [fieldName, field] of Object.entries(rule.fields))
        {
            if (field.template)
            {
                let json = JSON.stringify(require(`@/../public/.subdomains/api/rules/field_templates/${field.template.name}`))
                if (field.template.params)
                    for (const [name, param] of Object.entries(field.template.params))
                    {
                        json = json.replaceAll(`$${name}`, param)
                    }
                const template = JSON.parse(json)
                const newField = ObjectHelper.mergeDeep({}, template, field)
                delete newField.template
                rule.fields[fieldName] = newField
            }
        }

        function fillPermissions(permissions: RulePermissions)
        {
            if (rule.permissions.write !== undefined)
            {
                if (permissions.create === undefined) permissions.create = permissions.write
                if (permissions.update === undefined) permissions.update = permissions.write
                if (permissions.delete === undefined) permissions.delete = permissions.write
            }

            if (rule.permissions.read !== undefined)
            {
                if (permissions.get === undefined) permissions.get = permissions.read
                if (permissions.list === undefined) permissions.list = permissions.read
            }

            return permissions;
        }

        if (!inherited)
        {
            if (rule.permissions === undefined) rule.permissions = {}
            if (rule.permissions.write === undefined) rule.permissions.write = true
            if (rule.permissions.read === undefined) rule.permissions.read = true

            fillPermissions(rule.permissions)
            for (const field of Object.values(rule.fields)) field.permissions = ObjectHelper.mergeDeep({}, rule.permissions, fillPermissions(field.permissions ?? {}))
        }

        return rule
    }

    rule(rulename: string)
    {
        const args: PostListArguments = {}

        const root = this
        const methods = {
            filter(...filters: PostFilter[])
            {
                args.filters = filters
                return methods
            },
            owner(ownerId: string)
            {
                args.owner = ownerId
                return methods
            },
            order(order: PostOrder)
            {
                args.order = order
                return methods
            },
            limit(limit: number)
            {
                args.limit = limit
                return methods
            },
            after(index: number)
            {
                args.after = index
                return methods
            },
            async firstId()
            {
                this.limit(1)
                const id = (await this.list())[0]
                if (id === undefined) throw new Error('firstId failed, id is undefined')
                return id
            },
            async list()
            {
                return await root.list(rulename, args)
            },
            async first<T>(id?: string): Promise<Post<T>>
            {
                if (id === undefined) { this.limit(1); id = (await this.list())[0] }
                if (id === undefined) throw new Error('first failed, id is undefined')
                return (await root.get<T>(rulename, [id]))[id]
            },
            async get<T>(...ids: string[]): Promise<Dictionary<Post<T>>>
            {
                if (ids.length === 0) ids = await this.list()
                if (ids.length === 0) return {}
                const result = await root.get<T>(rulename, ids)
                return result
            }
        }

        return methods
    }

    private async pullPosts<T>(rulename: string, ids: string[])
    {
        const results = await Api.Do<Dictionary<Post<T>>>("db_get",
            {
                rule: rulename,
                ids: ids
            })

        for (const [postId, post] of Object.entries(results))
        {
            if (!this.verifyPost(rulename, post)) throw new Error("can't verify post after pull request")
            this.decodePost(rulename, post)
            const local = this.posts[postId]
            if (local) post.values = Object.assign({}, local.values, post.values)
            results[postId] = Vue.set(this.posts, postId, post)
        }

        return results
    }

    private encodePost(rulename: string, values: Dictionary<any>)
    {
        const rule = this.getRule(rulename)
        const result: Dictionary<any> = {}
        for (const [fieldName, field] of Object.entries(rule.fields))
        {
            const value = values[fieldName]
            switch (field.type)
            {
                case 'picture':
                    const pictures = Array.isArray(value) ? value : [value]
                    result[fieldName] = pictures.map((picture) =>
                    {
                        if (typeof picture !== 'string') return picture
                        const s = 'https://cdn.1petci.com'
                        if (picture.startsWith(s)) return picture.substr(s.length)
                    })
                    if (!field.multiple) result[fieldName] = result[fieldName][0] 
                    break
                default:
                    result[fieldName] = value
                    break
            }
        }

        return result
    }

    private decodePost(rulename: string, post: Post<any>)
    {
        const rule = this.getRule(rulename)
        for (const [fieldName, field] of Object.entries(rule.fields))
        {
            const value = post.values[fieldName]
            switch (field.type)
            {
                case 'date':
                    post.values[fieldName] = new Date(value as number)
                    break
                case 'select':
                    field.options = field.options as RuleFieldOption[]
                    post.values[fieldName] = field.options && field.options.find(o => o.value === value)
                    break
                case 'picture':
                    if (value)
                    {
                        const parseSrc = (src: string) => 
                        {
                            try
                            {
                                const url = new URL(src)
                                if (url.hostname === location.hostname) src = `https://cdn.1petci.com${src}`
                            }
                            catch
                            {
                                src = `https://cdn.1petci.com${src}`
                            }
                            return src
                        }
                        if (!Array.isArray(value)) post.values[fieldName] = parseSrc(value)
                        else post.values[fieldName] = value.map((value) => parseSrc(value))
                    }
                    break
            }
        }

        return post
    }

    private verifyPost(rulename: string, post: Post<any>)
    {
        const rule = this.getRule(rulename)
        for (const [fieldName, field] of Object.entries(rule.fields))
        {
            // undefined means api is telling us it has no idea about that field, and null means api knows there might be something but there is nothing
            if (post.values[fieldName] === undefined) 
            {
                if (!field.permissions.read) continue
                else 
                {
                    console.warn(`can't verify post. ${rulename} -> ${fieldName} doesn't exist but its at least expected to be null.`, post)
                    return false
                }
            }
            else if (post.values[fieldName] === null)
            {
                if (field.optional) continue
                else 
                {
                    console.warn(`can't verify post. ${rulename} -> ${fieldName} value is empty(null) on purpose but the field is not optional.`, post)
                    return false
                }
            }
        }

        return true
    }

    async set(rulename: string, values: Dictionary<any>, postId?: string)
    {
        values = this.encodePost(rulename, values)
        const postData = await Api.Do<{ id: string }>('db_set', { rule: rulename, id: postId, values })
        modules.Snackbars.push(`${postId ? 'Güncellendi' : 'Kayıt Edildi'}`)
        this.pullPosts(rulename, [postData.id])
        return postData
    }

    async delete(rulename: string, id: string)
    {
        const postData = await Api.Do<{ id: string }>('db_delete', { rule: rulename, id })
        this.posts[id] = null
        modules.Snackbars.push(`Silindi`)
        return postData
    }

    async get<T>(rulename: string, ids: string[]): Promise<Dictionary<Post<T>>>
    {
        const results: Dictionary<Post<T>> = {}
        const toPull: string[] = []
        for (let id of ids)
        {
            const local = this.posts[id]
            if (local) 
            {
                if (this.verifyPost(rulename, local)) results[id] = local
                else toPull.push(id)
            }
            else toPull.push(id)
        }

        if (toPull.length > 0)
            Object.assign(results, await this.pullPosts(rulename, toPull))

        const ordered: Dictionary<Post<T>> = {}

        for (const postId of ids) ordered[postId] = results[postId]

        //console.log(results, ordered)
        return ordered
    }

    async list(rulename: string, args: PostListArguments = {}): Promise<string[]>
    {
        if (!args.order) args.order = PostOrder.desc
        if (!args.limit) args.limit = 125

        const result = await Api.Do<string[]>("db_list",
            {
                rule: rulename,
                ...args
            });

        return result
    }
})