












import adListVue from '@/components/ui/post/ad/list.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta'

@Component({ components: { adListVue } })
export default class adsPageVue extends Vue
{
	@Meta metaInfo() { return { title: this.meta_title, meta: [] } }
	meta_title: string = 'İlanlar'
	loading: boolean = true
}
