import Vue from "vue"
import VueSanitize from "vue-sanitize"

const defaultOptions = {
    allowedTags: ['a', 'b', 'strong', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    allowedAttributes:
    {
        'a': ['href']
    }
}

Vue.use(VueSanitize, defaultOptions)
