


























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class responsiveVue extends Vue
{
	@Prop({ default: "100%" }) readonly maxWidth!: number | string
	@Prop({ default: 1 }) readonly aspectRatio!: number
}
