import { Dictionary } from "../collections/Dictionary"

export class FunctionHelper
{
    static defineCachedFunc<T>(func: T, timeout: number = -1)
    {
        if (typeof func !== 'function') throw new Error('function type expected')

        const cachedResults: Dictionary<Promise<any>> = {}
        return (async (...params: any[]) =>
        {
            const key = JSON.stringify([params])

            const cachedResult = cachedResults[key]
            if (cachedResult) return await cachedResult

            const result: Promise<any> = func(...params)
            cachedResults[key] = result
            if (timeout > 0) setTimeout(() => delete cachedResults[key], timeout)
            return await result
        }) as any as T

    }
}

(window as any).test = FunctionHelper;