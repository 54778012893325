







import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import editor from '@tinymce/tinymce-vue'
import { CreateElement } from 'vue'
import { Dictionary } from '@/libs/collections/Dictionary'
import galleryVue from '../post/gallery/dialog.vue'

@Component({ components: { editor, galleryVue } })
export default class inputTextEditorVue extends Vue
{
	editor: any = null
	@Prop({ default: '' }) readonly value!: string

	galleryDialog = false
	galleryValue: string | string[] | null = null
	@Watch('galleryValue') galleryWatcher()
	{
		if (!this.galleryValue || this.galleryValue.length === 0) return
		let value = this.galleryValue
		this.galleryValue = null
		if (!Array.isArray(value)) value = [value]

		for (let v of value) this.editor.insertContent(`<img style="display: block; margin-left: auto; margin-right: auto;" height="auto" src="${v}"/>`)
	}



	openGallery(editor: any)
	{
		this.editor = editor
		this.galleryDialog = true
	}

	@Watch('value') valueWatcher()
	{

	}

	initObj = {
		component: this,
		height: 1000,
		menubar: false,
		plugins: [
			'advlist autolink lists link image charmap print preview anchor',
			'searchreplace visualblocks code fullscreen',
			'insertdatetime media table paste code help wordcount',
		],
		toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image mygallery | code',
		setup: function (editor: any)
		{
			this.component.editor = editor
			editor.ui.registry.addButton('mygallery', {
				text: 'Gallery',
				icon: 'gallery',
				onAction: () => this.component.openGallery(editor)
			});
		}
	}
}
