import Vue from 'vue'
import { Dictionary } from '@/libs/collections/Dictionary'
import { PromiseHelper } from '@/libs/helpers/Promise'
import { defineModule, VuexClassModule } from '@/modules/vuex-class-module'

export const LocalStorage = defineModule(new class extends VuexClassModule  
{
    storage: Dictionary<string> = {}

    setItem(key: string, value: string | null) 
    {
        if (!value) return this.removeItem(key)
        localStorage[key] = value
        Vue.set(this.storage, key, value)
        return value
    }
    removeItem(key: string)
    {
        localStorage.removeItem(key)
        Vue.delete(this.storage, key)
    }

    async start()
    {
        while (true)
        {
            const keys = Object.keys({ ...this.storage, ...Object.fromEntries(Array(localStorage.length).fill(null).map((v, i) => [localStorage.key(i), null])) })
            for (const key of keys)
            {
                if (!localStorage[key] && this.storage[key]) Vue.delete(this.storage, key)
                if (this.storage[key] !== localStorage[key]) Vue.set(this.storage, key, localStorage[key])
            }

            await PromiseHelper.Sleep(500)
        }
    }
})