export class StringHelper
{
    static generateRandomId(length: number = 8)
    {
        var result = ''
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        var charactersLength = characters.length
        for (var i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength))
        return result
    }
}