















import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import PageNotFoundVue from '@/views/404.vue';
import { PromiseHelper } from '@/libs/helpers/Promise';
import { Dictionary } from '@/libs/collections/Dictionary';
import { Route } from 'vue-router';

@Component({ components: { PageNotFoundVue } })
export default class pageSwitcherVue extends Vue
{
	@Prop({ default: '0px' }) readonly paddingTop!: string
	@Prop({ default: '0px' }) readonly paddingBottom!: string
	@Prop({ default: '0px' }) readonly paddingLeft!: string
	@Prop({ default: '0px' }) readonly paddingRight!: string

	scrollPositionsByPath: Dictionary<{ x: number, y: number }> = {}
	pageKey: null | string = null

	mounted()
	{
		this.$router.beforeEach((to, from, next) => (this.LeaveRoute(from), next()))
		this.$router.afterEach(this.enterRoute)
		this.enterRoute(this.$route)
	}

	LeaveRoute(from: Route)
	{
		this.scrollPositionsByPath[from.path] = { x: scrollX, y: scrollY }
	}

	enterRoute(to: Route)
	{
		const pageKey: string = (to.matched[1].components['default'] as any).cid
		if (this.pageKey === pageKey) return
		if (this.pageKey && to.meta.silentRoute) return
		this.pageKey = pageKey
	}


	@Watch('pageKey') pageKeyWatcher()
	{
		const scrollTo = this.scrollPositionsByPath[this.$route.path] ?? { x: 0, y: 0 }

		scroll(scrollTo.x, scrollTo.y)
		setTimeout(() => scroll(scrollTo.x, scrollTo.y))
	}

}
