













import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component
export default class colVue extends Vue
{
	@Prop() readonly cols!: string | number
	@Prop() readonly sm!: string | number
	@Prop() readonly md!: string | number
	@Prop() readonly lg!: string | number
	@Prop() readonly xl!: string | number

	@Prop({ default: '0' }) readonly grow!: string | number
	@Prop({ default: '0' }) readonly shrink!: string | number

	get breakpoint()
	{
		switch (this.$vuetify.breakpoint.name)
		{
			case 'xl':
				if (this.xl !== undefined) return this.xl
			case 'lg':
				if (this.lg !== undefined) return this.lg
			case 'md':
				if (this.md !== undefined) return this.md
			case 'sm':
				if (this.sm !== undefined) return this.sm
			case 'xs':
				if (this.cols !== undefined) return this.cols

			default:
				return '1'
		}
	}
}
