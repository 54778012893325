import { PromiseHelper } from '@/libs/helpers/Promise';
import { defineModule, VuexClassModule } from '@/modules/vuex-class-module';

export const Tasks = defineModule(new class extends VuexClassModule 
{
    taskQueue: Array<{ func: Function, resolver: (value: any) => void, reject: (reason?: any) => void }> = []

    queue<T>(func: () => T): Promise<T>
    {
        return new Promise<T>((resolver, reject) => this.taskQueue.push({ func, resolver, reject }))
    }

    async start()
    {
        while (true)
        {
            if (this.taskQueue.length === 0) 
            {
                await PromiseHelper.Sleep(100)
                continue
            }
            const promises = []
            for (let i = 0; i < 2; i++)
            {
                promises.push((async () =>
                {
                    const task = this.taskQueue.shift()
                    if (!task) return
                    console.log('task queue:', this.taskQueue.length)
                    try
                    {
                        let result = task.func()
                        if (result instanceof Promise) result = await result
                        else await PromiseHelper.Sleep()
                        task.resolver(result)
                    }
                    catch (ex)
                    {
                        task.reject(ex)
                    }
                })())
            }
            await Promise.all(promises)
        }
    }
})