









import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import formVue from '../../form.vue';
import { Dictionary } from '@/libs/collections/Dictionary';
import { ObjectHelper } from '@/libs/helpers/Object';
import { PromiseHelper } from '@/libs/helpers/Promise';

@Component({
	components: {
		// importing it like that cuz its recursive when called from (dynamic group) -> (texteditor) -> (gallery) -> this ^¬^
		dynamicFieldGroupVue: () => import('@/components/ui/post/dynamicGroup.vue'),
		formVue
	}
})
export default class galleryAlbumFormVue extends Vue
{
	@PropSync('postId') postIdSync?: string

	values: Dictionary<any> = {}
	loading = false

	async pushAlbum()
	{
		this.loading = true
		try
		{
			const respond = await this.$modules.Posts.set('user_gallery', this.values, this.postIdSync)
			this.postIdSync = respond.id
			this.$emit('submit')
		}
		catch (ex)
		{
			throw ex
		}
		finally
		{
			this.loading = false
		}
	}
}
