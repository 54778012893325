














import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component
export default class parallaxVue extends Vue
{
	@Ref('parallax') parallax!: HTMLElement
	@Prop() readonly src!: string
	@Prop() readonly width!: string
	@Prop() readonly height!: string

	created() { window.addEventListener('resize', this.updateParallax); window.addEventListener('scroll', this.updateParallax) }
	destroyed() { window.removeEventListener('resize', this.updateParallax); window.removeEventListener('scroll', this.updateParallax) }

	isActive = true
	activated() { this.updateParallax(); this.isActive = true }
	deactivated() { this.updateParallax(); this.isActive = false }

	isIntersecting = false
	onIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean)
	{
		this.isIntersecting = isIntersecting
		if (isIntersecting) this.updateParallax()
	}

	updateParallax()
	{
		if (!this.isIntersecting) return
		if (!this.isActive) return
		if (!this.parallax) return // i dont know why its become undefined time to time,, maybe right after destroy? idk
		const rect = this.parallax.getBoundingClientRect()
		if (rect.bottom < -100 || rect.top > innerHeight + 100) return
		this.parallax.style.backgroundPosition = `center calc(${(100 * (1 - rect.bottom / (innerHeight + rect.height)))}%)`
		this.parallax.style.backgroundSize = rect.height > rect.width ? 'auto 150%' : '150% auto'
	}
}
