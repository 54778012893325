




















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Dictionary } from '@/libs/collections/Dictionary'
import { Rule } from '@/store/modules/posts/types'
import dynamicFieldGroupVue from '../dynamicGroup.vue'
import postVue from '../post.vue'
import smoothResizerVue from '../../smoothResizer.vue'
import adBlockVue from './block.vue'

@Component({ components: { dynamicFieldGroupVue, postVue, smoothResizerVue, adBlockVue } })
export default class adFormVue extends Vue
{
	validated = false
	loading = false
	@Prop() readonly initPostId?: string

	values: Dictionary<any>[] = []

	currentStep = -1

	postId: string | null = null

	get steps()
	{
		return [
			{
				title: "İlan",
				rulename: 'ad'
			},
			{
				title: "İletişim",
				rulename: 'ad_contact'
			},
			{
				title: "Tür",
				rulename: `ad_${this.values[0] ? this.values[0].type : undefined}`
			},
			{
				title: "Yayınla",
				template: "publish"
			},
			{
				title: "Yayınlandı",
				template: "complete"
			}]
	}


	mounted()
	{
		this.currentStep = 0
	}

	submit()
	{
		(async () =>
		{
			try
			{
				this.loading = true
				const values = Object.assign({}, ...Object.values(this.values))
				const rulename = `ad_${values.type}`
				delete values.type
				this.postId = (await this.$modules.Posts.set(rulename, values, this.initPostId)).id
				this.currentStep = this.steps.length - 1
			}
			catch (ex)
			{
				this.$modules.Snackbars.push(ex.message)
				console.error(ex)
			}
			finally
			{
				this.loading = false
			}
		})()
	}
}
