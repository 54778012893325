












import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta'

@Component
export default class PageNotFoundVue extends Vue
{
	@Meta metaInfo() { return { title: this.meta_title, meta: [{ name: 'robots', content: 'noindex' }] } }
	meta_title: string = 'Sayfa Bulunamadı'
}
