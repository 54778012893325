import Vue from 'vue'
import { registerModules } from '@/modules/vuex-class-module'
import { store } from '.'

import { Article } from './modules/article';
import { Auth } from './modules/auth';
import { Posts } from './modules/posts';
import { Site } from './modules/site';
import { Snackbars } from './modules/snackbar';
import { Tasks } from './modules/tasks/task';
import { LocalStorage } from './modules/localStorage'

const modules = {
  Auth,
  Site,
  Article,
  Posts,
  Snackbars,
  Tasks,
  LocalStorage
}

const { vuexClassModule } = registerModules(store, modules)

Vue.use(vuexClassModule)

declare module 'vue/types/vue' {
  interface Vue
  {
    $modules: typeof modules
  }
}


export { modules }