
































import articleDetailVue from '@/components/ui/post/article/detail.vue'
import { Meta } from '@/plugins/decorators/vue-meta'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Post } from '@/store/modules/posts/types'
import colVue from '@/components/ui/grid/col.vue'
import postVue from '@/components/ui/post/post.vue'
import adBlockVue from '@/components/ui/post/ad/block.vue'
import loaderVue from '@/components/ui/loader.vue'
import { MetaInfo } from 'vue-meta'

@Component({ components: { articleDetailVue, colVue, postVue, adBlockVue, loaderVue } })
export default class articlePageVue extends Vue
{
	@Meta metaInfo() { return this.meta }
	meta: MetaInfo | null = null


	postId: string | null = null
	get post()
	{
		return this.postId ? this.$modules.Posts.posts[this.postId] : null
	}

	get articleUrl()
	{
		return this.$route.params.article
	}

	recentAds: string[] | null = null

	async mounted()
	{
		// move these functions to vuex later
		this.recentAds = await this.$modules.Posts.rule('ad').filter(['listed', '=', 't']).limit(12).list()
	}

	isActive = true
	activated() { return this.isActive = true }
	deactivated() { return this.isActive = false }

	@Watch('isActive')
	@Watch('articleUrl', { immediate: true }) async routeWatcher()
	{
		if (!this.isActive) return
		if (this.post && this.post.values.url === this.articleUrl) return

		this.postId = null
		this.meta = null;

		try
		{
			this.postId = await this.$modules.Article.GetArticleIdFromUrl(this.articleUrl)
			this.meta = { title: this.post?.values.title, meta: [ { name: "description", content: this.post?.values.text } ] }
		}
		catch (ex)
		{
			console.error(ex)
			this.$modules.Site.pageNotFound()
		}

	}
}
