/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { modules } from './store/classModules'
import { Snackbar } from './store/modules/snackbar/types'

let snackbar: Snackbar | undefined
const print = (message: string) => {
  if (snackbar) snackbar.message.text = message
  else snackbar = modules.Snackbars.push(message, -1)
}

const close = () => {
  snackbar?.remove()
  snackbar = undefined
}

if (process.env.NODE_ENV === 'production')
{
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready()
    {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered()
    {
      console.log('Service worker has been registered.')
    },
    cached()
    {
      console.log('Content has been cached for offline use.')
      print('Uygulama Güncellendi.')
      setTimeout(close, 2000)
    },
    updatefound()
    {
      console.log('New content is downloading.')
      print('Uygulama Güncelleniyor...')
    },
    updated()
    {
      console.log('New content is available; please refresh.')
      print('Yeni versiyon yüklendi. Sayfa yenileniyor.')
      setTimeout(() => window.location.reload(), 1000)
    },
    offline()
    {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error)
    {
      console.error('Error during service worker registration:', error)
    }
  })
}
