







































































































































































































import { RuleField, RuleFieldSearchOptions, RuleFieldAutoFunc } from '@/store/modules/posts/types';
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { VTextField } from 'vuetify/lib';
import textEditorVue from '@/components/ui/inputs/textEditor.vue';
import inputTextEditorVue from '@/components/ui/inputs/textEditor.vue';
import inputPictureVue from '@/components/ui/inputs/picture.vue';
import { Dictionary } from '@/libs/collections/Dictionary';
import codeNumberVue from '../inputs/codeNumber.vue';

@Component({ components: { inputPictureVue, inputTextEditorVue, codeNumberVue } })
export default class dynamicFieldsVue extends Vue
{
	@Prop() readonly ignoreTypingRules?: boolean

	@Prop() readonly value?: any
	@Prop({ required: true }) readonly field!: RuleField
	@Prop() readonly fieldname?: string
	@Prop() readonly loading?: boolean
	@Prop() readonly values?: Dictionary<any>

	get label() { return `${this.field.text ?? this.fieldname}${this.field.optional ? ' (isteğe bağlı)' : ''}` }
	get isGod() { return this.$modules.Auth.user && this.$modules.Auth.user.profile.role.value === 'admin' }

	typingRules: Function[] = []

	localValue: any = null
	dialog = false
	showPassword = false

	options: Array<{ text: string, value: string }> | null = null

	@Watch('field', { immediate: true }) async fieldWatcher()
	{
		switch (this.field.type)
		{
			case 'search':
				const rulename = (this.field.options as RuleFieldSearchOptions).rulename
				const ids = await this.$modules.Posts.list(rulename, (this.field as any).args)
				const posts = await this.$modules.Posts.get<any>(rulename, ids)

				this.options = Object.entries(posts).map(([id, post]) =>
				{
					return {
						text: post.values[(this.field as any).options.textField],
						value: id
					}
				})
				break
		}
		this.typingRules = this.generateFieldRules(this.field)
	}

	@Watch('value', { immediate: true }) valueWatcher()
	{
		if (this.value === undefined) return this.localValue = this.value
		switch (this.field.type)
		{
			case 'date':
				const date = (this.value as Date)
				this.localValue = date.toISOString().substring(0, 10)
				break
			default:
				this.localValue = this.value
				break
		}
	}

	@Watch('localValue') localValueWatcher()
	{
		if (this.localValue === undefined) return this.$emit('input', this.localValue)
		switch (this.field.type)
		{
			case 'text':
				if (!this.localValue) this.$emit('input', undefined)
				else this.$emit('input', this.localValue)
				break
			case 'date':
				this.$emit('input', new Date(this.localValue))
				break
			default:
				this.$emit('input', this.localValue)
				break
		}
	}

	dateMinMaxCalc(minmax: string | number)
	{
		if (typeof minmax === 'number') minmax = minmax.toString()
		if (minmax[0] === '-' || minmax[0] === '+') return new Date(new Date().getTime() + parseInt(minmax)).toISOString()
		else return new Date(parseInt(minmax)).toISOString()
	}

	runAutoFuncs()
	{
		if (!this.field.auto?.funcs) return
		let value = this.localValue
		for (const func of this.field.auto.funcs)
		{
			switch (func.name)
			{
				case 'clear':
					value = ''
					break
				case 'appendText':
					value += func.text
					break
				case 'appendField':
					if (this.values && func.field && this.values[func.field]) 
					{
						const fieldValue = this.values[func.field].value ?? this.values[func.field]
						if (typeof fieldValue === 'string') value += fieldValue
					}
					break
				case 'replace':
					if (value)
					{
						const patterns = Array.isArray(func.pattern!) ? func.pattern! : [func.pattern!]
						for (const pattern of patterns)
						{
							value = value.replaceAll(new RegExp(pattern, 'g'), func.replacement!)
						}
					}
					break
				case 'match':
					if (value)
					{
						const patterns = Array.isArray(func.pattern!) ? func.pattern! : [func.pattern!]
						for (const pattern of patterns)
							if (!new RegExp(pattern).test(value)) return `${this.field.text} doğru formatta değil.`
					}
					break
			}
		}
		this.localValue = value
		return true
	}

	@Watch('values', { immediate: true, deep: true }) valuesWatcher()
	{
		this.runAutoFuncs()
	}

	generateFieldRules(field: RuleField)
	{
		const r: Function[] = []
		r.push((v: any) => !!field.optional || !!v && (!Array.isArray(v) || v.length != 0) || `${field.text} zorunlu.`)
		if (field.auto) r.push(() => this.runAutoFuncs())
		switch (field.type)
		{
			case 'text':
				r.push((v: string) => !field.min || !v || v.length >= field.min || `${field.min} karakterden kısa olamaz.`)
				r.push((v: string) => !field.max || !v || v.length <= field.max || `${field.max} karakterden uzun olamaz.`)
				break;
			case 'number':
				r.push((v: number | undefined) => !field.max || !v || v <= field.max || `${field.max} büyük olamaz.`)
				r.push((v: number | undefined) => !field.min || !v || v >= field.min || `${field.min} küçük olamaz.`)
				break;
		}

		return r;
	}
}
