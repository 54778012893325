var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('parallax-vue',{staticClass:"py-5",attrs:{"src":"@/assets/images/bg11.jpg"}},[_c('responsive-vue',{staticClass:"ma-auto",attrs:{"aspect-ratio":14 / 7,"max-width":"1300px"}},[_c('div',{staticClass:"pa-1",staticStyle:{"width":"100%","height":"100%"}},[_c('v-card',{attrs:{"width":"100%","height":"100%"}},[_c('v-carousel',{staticStyle:{"background":"var(--v-primary-base)"},attrs:{"cycle":"","width":"100%","height":"100%"}},[_c('carousel-items-vue',{attrs:{"galleryId":_vm.carouselGalleryId}})],1)],1)],1)])],1),_c('v-container',{staticClass:"max-width",attrs:{"fluid":""}},[_c('section-vue',[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" En Son Açılan İlanlar "),_c('v-btn',{staticClass:"my-2",attrs:{"to":{ name: 'ads' },"outlined":"","x-small":"","color":"primary"}},[_vm._v("Bütün İlanları Gör")])],1),_c('v-divider'),_c('ad-list-vue',{attrs:{"limit":12,"filters":[['listed', '=', 't']],"showFilter":false}})],1),_c('section-vue',[_c('div',[_c('span',{staticClass:"text-h5 text-center"},[_vm._v("Danışman Hekimlerimiz")]),_c('span',{staticClass:"text-caption ml-3"},[_vm._v("Profesyonelleri tercih edin!")]),_c('v-divider'),_c('post-list-vue',{attrs:{"rulename":"team","justify":"center","filters":[['field', '=', 'vet']],"lg":2,"sm":3,"cols":6},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var postId = ref.postId;
return [_c('post-vue',{attrs:{"rulename":"team","postId":postId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var post = ref.post;
return [_c('team-person-block-vue',{attrs:{"teamPerson":post}})]}}],null,true)})]}}])})],1)]),_c('section-vue',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6","cols":"12"}},[_c('v-img',{staticClass:"imgBox ma-auto",attrs:{"max-width":"500px","aspect-ratio":"1","contain":"","src":require("@/assets/images/img11.png")}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('h2',{staticClass:"text-h5"},[_vm._v("Kedi Sahipi Olanlar İçin")]),_c('v-row',{staticClass:"py-5",staticStyle:{"max-width":"900px"}},_vm._l(([
								{
									icon: 'mdi-magnify',
									title: 'Uzmanlarımızın tavsiyelerini okudunuz mu?',
									text: 'Veteriner Hekimlerimiz ve Kedi Irk Bilimi Uzmanlarımızın tavsiyelerini okudunuz mu?'
								},
								{
									icon: 'mdi-card-account-details-outline',
									title: 'Kedinizin şecere belgesi var mı?',
									text: 'Kedinizin safkanlığından emin misiniz? Dünyanın en büyük Kedi Irk Bilimi Derneklerinin yetkili hakemlerinin onayıyla şecere belgenizi alın!'
								},
								{
									icon: 'mdi-check-outline',
									title: 'Dünya standartlarında kediler yetiştirmek ister misiniz?',
									text: 'Kedinizi kısırlaştırmaya karşı mısınız? Onun üreme iç güdüsünü doğru yönlendirerek ırkına katkıda bulunun!'
								}
							]),function(item,i){return _c('v-col',{key:i,staticClass:"py-3 d-flex align-center",attrs:{"cols":"12","three-line":""}},[_c('span',{staticClass:"ma-5"},[_c('v-btn',{attrs:{"fab":"","color":"primary"}},[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(item.icon)}})],1)],1),_c('span',[_c('h3',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text-caption font-weight-light"},[_vm._v(_vm._s(item.text))])])])}),1),_c('v-btn',{attrs:{"color":"primary","rounded":"","to":{ name: 'ads' }}},[_vm._v("SAHİPLENİN")]),_c('span',{staticClass:"mx-3"},[_vm._v("veya")]),_c('v-btn',{attrs:{"rounded":"","to":{ name: 'new-ad' }}},[_vm._v("İLAN VERİN")])],1)],1)],1),_c('section-vue',[_c('v-row',{staticClass:"flex-wrap-reverse"},[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('h2',{staticClass:"text-h5"},[_vm._v("İstediğiniz Kedi Irkı Hakkında Bilgi Alın")]),_c('v-row',{staticClass:"py-5"},_vm._l(([
								{
									icon: 'mdi-pencil-outline',
									title: 'Tercihlerinizi Not Alın!',
									text:
										'İran Kedisi (Persian Cat) mi? Exotic Shorthair mı? Dişi mi? Erkek mi ? Safkan mı? Kırmamı mı? Seçim yapmak için yeterli teknik bilginiz var mı? Bilgi almak ve seçim yapmak için ansiklopedi sayfamızı inceleyebilirsiniz. Seçimlerinizi not alın.'
								},
								{
									icon: 'mdi-thumb-up-outline',
									title: 'Acele Etmeyin!',
									text:
										'Evinizde 8-14 yıl beraber yaşayacagınız dostunuzun ırk ve genetik özelliklerini kendi kişiliginize uygun olarak seçmelisiniz. 1-2 hafta erken yavru sahiplenmek için size uygun olmayan bir yavrunun ve kendi hayatınızı karartmayın.'
								},
								{
									icon: 'mdi-heart-outline',
									title: 'Kırma ile Safkan Yavruyu Ayırt Edebiliyor musunuz?',
									text:
										'Size en uygun özellikleri seçtikten sonra yapılan en büyük hata 200 - 300 TL daha uygun fiyatlı oldugu için safkan zannederek alınan kırma yavrular. Kendinize en uygun özelliklerde seçtiginiz yavrunun safkan olmaması bir gün eve geldiginizde paramparça hale gelmiş koltuk takımınızla veya boydan boya parçalanmış perdelerinizi yerde görmenize sebep olabilir.'
								}
							]),function(item,i){return _c('v-col',{key:i,staticClass:"py-3 d-flex align-center",attrs:{"cols":"12","three-line":""}},[_c('span',{staticClass:"ma-5"},[_c('v-btn',{attrs:{"fab":"","color":"primary"}},[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(item.icon)}})],1)],1),_c('span',[_c('h3',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text-caption font-weight-light"},[_vm._v(_vm._s(item.text))])])])}),1),_c('v-btn',{attrs:{"rounded":"","to":{ name: 'articles' }}},[_vm._v("IRKLARI TANI")]),_c('span',{staticClass:"mx-3"},[_vm._v("veya")]),_c('v-btn',{attrs:{"color":"primary","rounded":"","to":{ name: 'ads' }}},[_vm._v("SAHİPLEN")])],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-img',{staticClass:"imgBox ma-auto",attrs:{"max-width":"500px","aspect-ratio":"1","contain":"","src":require("@/assets/images/img21.png")}})],1)],1)],1),_c('section-vue',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"text-h5"},[_vm._v("FARKIMIZI YAŞAMAYA HAZIR MISINIZ?")]),_c('span',{staticClass:"text-subtitle-1"},[_vm._v("DÜNYA STANDARTLARINDA HİZMET ANLAYIŞI")])])]},proxy:true}])},[_c('v-row',_vm._l(([
						{
							icon: 'mdi-forum-outline',
							title: 'Her zaman yanınızdayız',
							text:
								'Kedinizi aldınız, asıl destek şimdi başlıyor. Yavrunuzu alana kadar değil, profesyonel ekibimizle her zaman yanınızdayız. Kulübümüzden sahiplendiğiniz tüm kedilerinizin ömürleri boyunca yardıma ihtiyaç duydugunuz her anda telefonunuz kadar yakınınızdayız. Ayrıca Ansiklopedi sayfamızdan ihtiyacınız olan tüm teknik ve bakım bilgilerine ulaşabilirsiniz.'
						},
						{
							icon: 'mdi-heart-multiple-outline',
							title: 'Yavrunuzu Tanıyan Bir Ekip',
							text:
								'Kedileriniz ile duygusal bağ kurmuş bir ekip! Uzman kadromuz size Kedinizin bakımı, sağlık problemleri hakkında her konuda yardımcı olmaya hazır. Persian Cat (İran Kedisi) ve Exotic Shorthair ırkına gönül vermiş, bu konuda tahsil yapmış uzun yılların getirdiği tecrübelerinden faydalanabileceğiniz hekim ve yetkili ırk yetiştiricilerinden oluşturulmuştur.'
						},
						{
							icon: 'mdi-lightbulb-outline',
							title: 'Gelişmiş Teknolojik Altyapı',
							text:
								'Veteriner/Zootekni Hekimlerimiz tarafından maddi kazanç amacı güdülmeden, sahiplendirilmeye uygun görülen safkan Persian Cat (İran Kedisi) veya Exotic Shorthair ırkı yavrularımızın bakımı ve rutin kontrolleri Dünya Standartlarında dinamik bir teknolojik alt yapının yardımıyla yapılmaktadır.'
						},
						{
							icon: 'mdi-account-multiple-outline',
							title: 'İstisnasız Sağlık Garantisi',
							text:
								'Yavrularımız Veteriner/Zootekni Hekimlerimizin gözetiminde bağışıklık güçlendirici takviyelerle 9 hafta aralıksız olarak anne sütü ile beslenmektedir. Kulübümüzün yavrularına sadece ailesine ulaşana kadar değil, ömürlerinin sonuna kadar karşılaşacakları virüslerle başa çıkabilecek güçlü bir bağışıklık kazandırmadan sahiplendirmiyoruz.'
						}
					]),function(item,i){return _c('v-col',{key:i,attrs:{"md":"3","cols":"12"}},[_c('v-card',{staticClass:"pa-5 ma-auto text-center fill-height",staticStyle:{"border-color":"var(--v-primary-base)"},attrs:{"outlined":"","shaped":""}},[_c('v-btn',{staticClass:"mb-5",attrs:{"color":"primary","fab":"","x-large":""}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('h3',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(item.text))])],1)],1)}),1)],1),_c('section-vue',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"text-h5"},[_vm._v("BU KADAR İNSAN YANILIYOR OLAMAZ!")]),_c('span',{staticClass:"text-subtitle-1"},[_vm._v("KULÜBÜMÜZDEN HİZMET ALAN VEYA YAVRU SAHİPLENEN AİLELERİMİZİN YORUMLARI")])]},proxy:true}])},[_c('post-list-vue',{attrs:{"cols":12,"md":6,"limit":2,"order":"desc","rulename":"quotes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var postId = ref.postId;
return [_c('post-vue',{attrs:{"rulename":"quotes","postId":postId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var post = ref.post;
return [(post)?_c('quote-block-vue',{attrs:{"name":post.values.name,"picture":post.values.picture,"text":post.values.text,"title":post.values.title}}):_c('v-skeleton-loader',{attrs:{"type":"paragraph@4"}})]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }