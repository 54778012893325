


















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import postVue from '../post.vue'
import { Post } from '@/store/modules/posts/types'
import parallaxVue from '../../parallax.vue'
import colVue from '../../grid/col.vue'
import responsiveVue from '../../responsive.vue'
import imgPrefetchVue from '../../imgPrefetch.vue'

@Component({ components: { postVue, parallaxVue, colVue, responsiveVue, imgPrefetchVue } })
export default class articleBlockVue extends Vue
{
	@Prop() readonly article!: Post<any>
	textHtml = ''

	@Watch('article', { immediate: true }) articleWatcher()
	{
		if (!this.article) return
		this.textHtml = this.$sanitize(this.article.values.textPreview, { allowedTags: [], allowedAttributes: {} }).replaceAll(new RegExp('\\[(.*?)\\]', 'g'), '')
	}
}
