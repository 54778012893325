








































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { MessageType } from '@/store/modules/snackbar/types'
import dynamicFieldGroupVue from '../post/dynamicGroup.vue'
import formVue from '../form.vue'
import { Dictionary } from '@/libs/collections/Dictionary'

@Component({ components: { dynamicFieldGroupVue, formVue } })
export default class authFormVue extends Vue
{
	values: Dictionary<any> = {}

	async login()
	{
		await this.$modules.Auth.login({
			mail: this.values.email,
			password: this.values.password
		})
		this.$emit('done')
	}

	async register()
	{
		await this.$modules.Auth.register({
			mail: this.values.email,
			password: this.values.password,
			displayName: this.values.displayName
		})
		await this.$modules.Auth.login({
			mail: this.values.email,
			password: this.values.password
		})
		this.$emit('done')
	}

	forgotPasswordDone = false
	async forgotPassword()
	{
		await this.$modules.Auth.forgotPassword({ mail: this.values.email })
		this.forgotPasswordDone = true
	}
}
