





















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta'
import { Dictionary } from '@/libs/collections/Dictionary'
import dynamicFieldGroupVue from '@/components/ui/post/dynamicGroup.vue'
import formVue from '@/components/ui/form.vue'
import colVue from '@/components/ui/grid/col.vue'

@Component({ components: { dynamicFieldGroupVue, formVue, colVue } })
export default class profileSecurityPageVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	meta_title: string | null = 'Güvenlik'

	loading = true
	values: Dictionary<any> = {}

	get user() { return this.$modules.Auth.user }
	@Watch('user', { immediate: true }) userWatcher()
	{
		if (!this.user) return this.values = {}

		this.values = {
			mail: this.user.mail
		}

		this.loading = false
	}

	disabled = false
	get buttonText()
	{
		const texts: Array<string> = []

		if (this.values.mail !== this.user?.mail) texts.push('E-POSTA')
		if (this.values.newPassword) texts.push('ŞİFRE')

		this.disabled = texts.length == 0

		return `${texts.join(' VE ')} DEĞİŞTİR`
	}


	async editUser()
	{
		try
		{
			this.loading = true
			await this.$modules.Auth.editUser(this.values as any)
			this.$modules.Snackbars.push('Hesap bilgileri güncellendi.')
		}
		catch (ex)
		{
			this.$modules.Snackbars.push(ex)
		}
		finally
		{
			this.loading = false
		}
	}
}
