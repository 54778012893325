











































































































































import { Vue, Component } from 'vue-property-decorator'
import authFormVue from '@/components/ui/auth/form.vue'
import profileBarVue from '@/components/ui/profile/bar.vue'
import cookiePolicyVue from '@/components/common/cookiePolicy.vue'
import adFormVue from '@/components/ui/post/ad/form.vue'
import pageSwitcherVue from '@/components/common/pageSwitcher.vue'
import profileAvatarVue from '@/components/ui/profile/avatar.vue'
import profileNameVue from '@/components/ui/profile/name.vue'

@Component({ components: { authFormVue, profileBarVue, profileAvatarVue, profileNameVue, adFormVue, pageSwitcherVue, cookiePolicyVue } })
export default class defaultVue extends Vue
{
	leftNavActive = false

	get navItems()
	{
		return [
			{
				route: { path: '/' },
				text: 'Ana Sayfa',
				icon: this.$route.path === '/' ? 'mdi-home' : 'mdi-home-outline',
				bottomNav: true
			},
			{
				route: { name: 'ads' },
				text: 'İlanlar',
				icon: this.$route.name === 'ads' ? 'mdi-paw' : 'mdi-paw-outline',
				bottomNav: true
			},
			{
				route: { name: 'profile', params: { profile: this.$modules.Auth.user && this.$modules.Auth.user.profile.url } },
				text: 'İlanlarım',
				icon: this.$route.name === 'profile' ? 'mdi-account-details' : 'mdi-account-details-outline',
				userOnly: true,
				bottomNav: true
			},
			{
				route: { name: 'articles' },
				text: 'Ansiklopedi',
				icon: this.$route.name === 'articles' ? 'mdi-book' : 'mdi-book-outline',
				bottomNav: true
			},
			{
				route: { name: 'about' },
				text: 'Hakkımızda',
				icon: this.$route.name === 'about' ? 'mdi-information' : 'mdi-information-outline'
			},]
	}
}
