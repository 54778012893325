


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { SiteModes } from '@/store/modules/site'

@Component
export default class siteModeChangerVue extends Vue
{
	show = true

	get modes()
	{
		return Object.entries(SiteModes).map((entry) => ({ text: entry[0], value: entry[1] }))
	}

	currentMode: SiteModes | null = this.$modules.Site.mode

	@Watch('currentMode', { immediate: true }) currentModeWatcher()
	{
		if (this.currentMode) this.$modules.Site.mode = this.currentMode
	}
}
