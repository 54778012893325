









































































































































































































































	import { Vue, Component, Prop } from 'vue-property-decorator'
	import { Meta } from '@/plugins/decorators/vue-meta'
	import sectionVue from '@/components/ui/section.vue'
	import postVue from '@/components/ui/post/post.vue'
	import postListVue from '@/components/ui/post/list.vue'
	import teamPersonBlockVue from '@/components/ui/post/team/block.vue'

	@Component({ components: { sectionVue, postVue, postListVue, teamPersonBlockVue } })
	export default class aboutPageVue extends Vue
	{
		@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
		meta_title: string | null = 'Hakkımızda'

		specialityProgresses = [
			{
				text: 'Exotic Shorthair & Persian Cat Irk Bilimi',
				value: 87
			},
			{
				text: 'Exotic Shorthair & Persian Cat Bakımı',
				value: 100
			},
			{
				text: 'Biyokimyasal Parametreler ve Değerlendirmeleri',
				value: 73
			},
			{
				text: 'Exotic Shorthair & Persian Irk Yetiştiriciliği',
				value: 94
			},
			{
				text: 'Patoloji ve Klinik Laboratuar Teknikleri',
				value: 68
			},
			{
				text: 'Konsültasyon & Acil Müdahale Teknikleri',
				value: 78
			},
			{
				text: 'Gelişmiş Teknolojik Altyapı',
				value: 82
			}
		];

		faq = [
			{
				title: 'Kurulma Amacınız',
				text: `Dünya genelinde uzun yıllardır özenle korunarak üretilen Safkan Kedi Irkları’nın değeri, son yıllarda ülkemizde de fark edilmeye başladı. Ancak sadece maddi kazanç sağlamak amacı güden, Irk ve Cins Standartlarının Üretimi hakkında hiç bir bilgisi olmayan merdiven altı diye tabir edilen üreticilerin ülkemizdeki safkan ırk gelişimine verdiği zarar çok büyük boyutlara ulaşmıştır.

Safkan Kedi Irk Üretimi bu konu hakkında eğitim almış kişi veya kurumlarca yapılması gereken çok ciddi bilgi ve sorumluluk gerektiren bir bilim dalıdır. Kulübümüz Dünya’da Kedi Irk Bilimine bilimsel olarak katkı sağlayan tüm global ve yerel kedi ırk derneklerinin yolunda gitmektedir. Kulübümüz’ün birincil hedefi amatör kırma kedi üreticilerinin Safkan Kedi Irkına verdiği zararı minimize etmektir. Bu nedenle Safkan Irk Yetiştiriciliği sıfatını kazanmamış her amatör üreticiye gerekli eğitim ve altyapı desteğini sunarak bilinçlendirmek bu konuda atacağımız en önemli adımlardan bir tanesidir.`
			},
			{
				title: 'Türkiye\'de birçok kedi derneği var ! Aynı hizmeti mi veriyorsunuz?',
				text: `Türkiye’de son yıllarda Kedi Irk Dernekleri’nin sayısı artmaya başlamıştır. Kulübümüzün hizmet verdiği alan, şu an aktif olarak faaliyet gösteren kedi ırk derneklerinden çok farklıdır. Bu nedenle hiçbir Kedi Irk Derneğine rakip olmadığımız gibi bir çoğuna da fayda sağlayacak Teknolojik Altyapının mimarı olmaktayız.`
			},
			{
				title: 'Türkiye\'deki safkan kedi ırkına fayda sağlayacak teknolojik altyapılarınız nelerdir ?',
				text: `Facebook gibi Sosyal Medya araçlarının daha aktif kullanılmasıyla birlikte kırma kedilerin Safkan Irkmış gibi sahiplendirilmesi Safkan Kedi Irklarına verilen en büyük zararlardan bir tanesidir. Kırma Kedi Irk üretimini durdurmak ve Safkan Irk Sahiplenmek isteyen bilgisiz ailelerin sorgulama yapabileceği Veri Tabanları oluşturduk.

Örneğin X isimli Derneğin imkanlarıyla Exotic Shorthair cinsi bir kedi alacaksınız. Veri tabanımıza yavrunun Şecere Belge Numarasını girerek kedinin hangi derneğe kayıtlı olduğunu, hangi üretici tarafından yetiştirildiğini online olarak öğrenebileceksiniz. Böylece aldığınız Kedinin Şecere Belgesinin doğruluğuna ve kedinizin üretilme standartlarına hakim olacaksınız.`
			},
			{
				title: 'Safkan ırkları korumanız sokaklardaki sahipsiz hayvanları yok saymak değil midir?',
				text: `Aksine uzun vadede sokaklardaki sahipsiz kedilere fayda sağlayacaktır. Sokak Kedisi diye tabir edilen kediler de birer Irka mensuplar. Kedilerde köpeklerde olduğu gibi Sokak Köpeği diye bir kavram yoktur. Sokak Kedisi olarak adlandırılan Bombay de safkan bir ırktır. Evinde hareketli avcı ve sürekli oyun oynamaya hazır bir kedi isteyen herkes Bombay kediyi tercih edebilir.

Sokak Kedisi kavramının oluşmasının temelinde, bizim önüne geçmek istediğimiz; kırma kedi üretiminin artması yatmaktadır. Örneğin; evinde hareketsiz, sakin ve uysal bir İran kedisi beslemek isteyen bir aile daha uygun fiyatlı olduğu için kırma bir Chinchilla sahiplenir. Ancak kedinin gen haritasında fiziksel olarak Chinchilla varken kişiliğinde Bombay Kedi Irkının özellikleri olması nedeniyle evde çok hareketli ve avcı bir kedi ile karşı karşıya kalınır. Bir süre sonra kediyi sahiplenen ailenin eşyaları zarar görmeye başladığında bu kedinin sokağa atılması anlamına gelir. Bu nedenle Bombay de olsa Exotic Shorthair da olsa safkan olmalı ve aileler evde kendileri ile 15 yıl geçirecek kedilerinin özelliklerini seçebilmelilerdir.

Bu şekilde sokağa bırakılan hayvanların oranında ciddi bir azalma olacağını görmek tartışmasız bir gerçektir. Bir aile her kedi ırkını sevmek zorunda değildir. Sadece bir kedi ırkını sevmesi onun hayvan sever olmadığı anlamına gelmemektedir. `
			},
			{
				title: 'Kedileri ırk özelliklerine göre tercih etmek ayrımcılık değil mi ?',
				text: `İnsanların evleri kendilerine özeldir. Dolayısıyla orada başka bir canlının yaşaması durumunda onun kişilik özelliklerine kendisinin karar vermesi kadar doğal hiçbir şey olamaz. Bu nedenle sakin sessiz ve uysal bir kedi isteyenler İran Kedisi, hareketli avcı ve oyuncu kedi sevenler ise Bombay tercih etmelidir. Bu ayrımcılık değil insanların kişiliklerine göre kedi almak istemelerinin bir sonucudur.`
			},
			{
				title: 'Yavrularınıza safkan ırk garantisi veriyor musunuz?',
				text: `Kulübümüzden sahipleneceğiniz Exotic Shorthair ve Kısa Tüylü İran Kedisi (Persian Cat) tüm yavrularımızda % 100 ırk garantisi veriyoruz.

Ayrıca 4 nesildir üremesi dernek kayıtlarımızda olan, yetişkin kedilerimizin yavrularına genetik ırk garantisi dahi vermekteyiz.`
			},
			{
				title: 'Yavrularınız nasıl besleniyorlar?',
				text: `Yavru kedilerimizin sağlıklı gelişimi için gerekli olan tüm ihtiyaçları, veteriner hekimlerimizin kontrolünde hassas dengeler gözetilerek karşılanmaktadır. Her yeni doğan yavrumuz, iyi bir bağışıklık sistemi kazanması için gerekli olan anne sütünü 5 haftalık olana kadar yoğun olarak emer. Yavruların sindirim sistemi yaklaşık 5 haftalık olana kadar sadece annesinin sütünü sindirmek için uygundur. Zaman geçtikçe, süt şekeri (laktoz) daha zor sindirilmeye başlar. Bu dönemde sıvı beslenmeden, katı beslenmeye; kademeli olarak geçilir. Yavrularımızın anne sütünden kesilme döneminin 1. aşaması olan Sağlıklı Beslenme Alışkanlığını (Health Nutrition) kademeli olarak kazandırmaktayız. Sütten kesilmenin 2. aşaması büyümenin en yoğun olduğu zaman dilimine özel olarak formülize edilmiş olan ‘Royal Canin – Baby Cat’ ile beslenmeye devam eder. Yavrularımız yaklaşık 7 haftalık olduğunda, Veteriner Hekimlerimizin gözetiminde sütten tamamen kesilerek ‘Royal Canin – Baby Cat’ ve ihtiyacına uygun ıslak mamalar ile beslenmektedir.

Başarılı bir şekilde sütten kesilen Exotic Shorthair ve Kısa Tüylü İran Kedisi (Persian Cat) tüm yavrularımızda sindirim sistemi hala hassas ve yeterince gelişmemiştir. Bu nedenle, evinize gelir gelmez aniden beslenmesinin değiştirilmesi doğru bir tercih değildir. Bu dönemde seçeneğiniz mamanın hızlı sindirilme özelliğinin yanı sıra, kedinizin ırkına özel beslenme gereksinimlerini de karşılaması gerekir. Ayrıca bu mama, yüksek enerji deposu besinlerle sinir ve bağışıklık sisteminin sağlıklı bir şekilde gelişmesi için vazgeçilmezdir. Hatırlamakta fayda var; 10 haftalık bir Exotic Shorthair ve Kısa Tüylü İran Kedisi (Persian Cat), yetişkin bir kediye göre kilo başına 4 misli daha çok enerjiye ihtiyaç duyar. Bu nedenle yavrunuzun size ulaşmasından sonra, minimum 4 aylık olana kadar ‘Royal Canin – Baby Cat’ mama ile beslenmesi bağışıklık sisteminin hastalıklara karşı çok daha dirençli olmasını sağlayacaktır.`
			},
			{
				title: 'Yavrularınıza sağlık garantisi veriyor musunuz?',
				text: `Sahiplendiğiniz yavrulara 1 hafta boyunca viral ve parazit nedeniyle oluşması muhtemel rahatsızlıklar ortaya çıkması durumunda tedavisini yetkili kliniklerimizce tedavi ediyoruz. 7 takvim günü sonunda oluşacak herhangi bir hastalık durumunda, sorumluluk yavrunun yeni ailesine aittir. Bu durumda kulübümüzün danışmanlık dışında herhangi bir sorumluluğu yoktur.

Sahiplendirdiğimiz her yavruya Pet Sigorta yapıldığı taktirde 1 yıllık sağlık garantisi veriyoruz. Bu süre zarfında ‘Pet Sigorta Şartları’ belirtilen maddelerden herhangi birinin oluşması durumunda yavrunuzun tüm bakım ve tedavi masraflarını pet sigorta karşılayacaktır.

Sağlık Garantimizin kapsamını büyütmeniz için ‘Geniş Kapsamlı Pet Sağlık Sigortası’ hizmetlerimizden de faydalanabilirsiniz. Böylece ağır hastalıkların dışında normal veteriner masraflarınızı da minimize etmenize yardımcı oluyoruz.`
			}
		]
	}
