








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class formVue extends Vue
{
	@Prop() readonly buttonText?: string
	@Prop({ required: true }) readonly onSubmit!: () => Promise<void>
	@Prop() readonly loading?: boolean
	@Prop() readonly buttonDisabled?: boolean

	validated = false
	submiting = false

	get allLoading()
	{
		return this.submiting || this.loading
	}

	async submit()
	{
		this.submiting = true
		try 
		{
			await this.onSubmit()
		}
		catch (ex)
		{
			this.$modules.Snackbars.push(ex)
		}
		this.submiting = false
	}
}
