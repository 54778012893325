import { Dictionary } from '@/libs/collections/Dictionary';
import { StringHelper } from '@/libs/helpers/String';
import { defineModule, VuexClassModule } from '@/modules/vuex-class-module';
import Vue from 'vue';
import { MessageType, Snackbar, SnackbarMessage } from './types';

export const Snackbars = defineModule(new class extends VuexClassModule 
{
    private _snackbars: Dictionary<SnackbarMessage> = {}
    get snackbars() { return this._snackbars }

    push(message: SnackbarMessage | string | Error, timeout = 5000)
    {
        const id = StringHelper.generateRandomId()
        switch (typeof message)
        {
            case 'string':
                message = { text: message, type: MessageType.Info }
                break
            case 'object':
                if (message instanceof Error)
                    message = { text: message.message, type: MessageType.Error }
                break
        }

        Vue.set(this._snackbars, id, message)
        const snackbar: Snackbar = { message, remove: () => Vue.delete(this._snackbars, id), timeout }
        if (timeout > 0) setTimeout(snackbar.remove, timeout)
        return Object.freeze(snackbar)
    }
})