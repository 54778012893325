import { render, staticRenderFns } from "./card.vue?vue&type=template&id=36887fdd&"
import script from "./card.vue?vue&type=script&lang=ts&"
export * from "./card.vue?vue&type=script&lang=ts&"
import style0 from "./card.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VFlex,VLayout})
