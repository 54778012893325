



































import { Vue, Component, Prop } from 'vue-property-decorator'
import profileAvatarVue from './avatar.vue'
import profileNameVue from './name.vue'
import { UserProfile } from '@/store/modules/auth/types'
import loaderVue from '../loader.vue'
import parallaxVue from '../parallax.vue'
import colVue from '../grid/col.vue'

@Component({ components: { profileAvatarVue, profileNameVue, loaderVue, parallaxVue, colVue } })
export default class profileCardVue extends Vue
{
	@Prop() readonly profile?: UserProfile
	get shortAbout() 
	{
		const len = 64
		const str = this.profile?.about?.substring(0, len) ?? ''
		return str.length === len ? `${str}...` : str
	}
}
