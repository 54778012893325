























import dynamicFieldGroupVue from '@/components/ui/post/dynamicGroup.vue';
import { Dictionary } from '@/libs/collections/Dictionary';
import { PromiseHelper } from '@/libs/helpers/Promise';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta';
import formVue from '@/components/ui/form.vue';
import { modules } from '@/store/classModules';

@Component({ components: { dynamicFieldGroupVue, formVue } })
export default class profileEditPageVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	meta_title: string | null = 'Düzenle'

	values: Dictionary<any> = {}
	get postId() { return this.$modules.Auth.user && this.$modules.Auth.user.profilePostId }
	get loading() { return !this.postId || !modules.Posts.posts[this.postId] }

	async save()
	{
		await this.$modules.Posts.set('user_profile', this.values, this.postId ?? undefined)
	}
}
