



















import { Dictionary } from '@/libs/collections/Dictionary'
import { ObjectHelper } from '@/libs/helpers/Object'
import { RuleField } from '@/store/modules/posts/types'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { directive } from 'vue/types/umd'
import dynamicFieldsVue from './dynamic.vue'
import loaderVue from '../loader.vue'

@Component({ components: { dynamicFieldsVue, loaderVue } })
export default class dynamicFieldGroupVue extends Vue
{
	@Prop({ required: true }) readonly rulename!: string
	@Prop({ default: false }) readonly inherits!: boolean
	@Prop() readonly postId?: string | null
	@Prop() readonly value?: Dictionary<any>

	@Prop() readonly loading?: boolean

	get rule() { return this.$modules.Posts.getRule(this.rulename, !this.inherits) }
	get isUpdate() { return this.postId !== undefined } // if its null that means its an update too, its just we dont know the id yet so probably loading

	get isGod() { return this.$modules.Auth.user && this.$modules.Auth.user.profile.role.value === 'admin' }

	localValues: Dictionary<any> = {}

	get valuesForAutoFuncs()
	{
		return {...this.localValues, $id: this.postId ?? '$id'}
	}

	@Watch('value', { deep: true, /*immediate: true*/ }) valueWatcher() 
	{
		if (!this.value) return
		if (Object.keys(this.value).length === 0) return
		this.localValues = this.value
	}
	@Watch('localValues', { deep: true }) localValuesWatcher() 
	{
		this.$emit('input', this.localValues)
	}

	@Watch('postId', /*{ immediate: true }*/) async postIdWatcher(n?: string, o?: string)
	{
		if (!this.postId) return this.fillDefaults()
		try
		{
			await this.fillPost(this.postId)
		}
		catch (ex)
		{
			console.error(ex)
			this.fillDefaults()
		}
	}

	hiddenFields: string[] = []
	fieldDefaults: Dictionary<any> = {}

	created()
	{
		this.$store.watch(() => this.$modules.Site.adDefaults, (adDefaults) =>
		{
			this.hiddenFields = []
			this.fieldDefaults = {}
			if (this.rulename !== 'ad' && !this.rulename.startsWith('ad_')) return
			if (adDefaults.type) 
			{
				this.hiddenFields.push('type')
				this.fieldDefaults.type = adDefaults.type
			}
			if (adDefaults.petType) 
			{
				this.hiddenFields.push('pet_type')
				this.fieldDefaults.pet_type = adDefaults.petType
			}
		}, { immediate: true })
		this.postIdWatcher(this.postId ?? undefined, undefined)
		this.valueWatcher()
	}

	fillDefaults()
	{
		this.localValues = Object.fromEntries(Object.entries(this.rule.fields)
			.filter(([fieldName, field]) => (field.default || this.fieldDefaults[fieldName]) && this.isFieldVisible(field))
			.map(([fieldName, field]) =>
			{
				console.log(fieldName, this.fieldDefaults[fieldName])
				return [fieldName, this.fieldDefaults[fieldName] ?? field.default]
			}))
	}

	async fillPost(postId: string)
	{
		const post = await this.$modules.Posts.rule(this.rulename).first<any>(postId)

		this.localValues = Object.fromEntries(Object.entries(post.values)
			.filter(([key, value]) => value && this.rule.fields[key] && this.isFieldVisible(this.rule.fields[key]))
			.map(([key, value]) => [key, (value as any)?.value ?? value]))
	}

	isFieldVisible(field: RuleField)
	{
		return this.isGod || field.permissions[this.isUpdate ? 'update' : 'create']
	}
}
