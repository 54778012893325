














	import adDetailVue from '@/components/ui/post/ad/detail.vue'
	import { Meta } from '@/plugins/decorators/vue-meta'
	import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
	import adListVue from '@/components/ui/post/ad/list.vue'
	import { Post } from '@/store/modules/posts/types'
	import { UserProfile } from '@/store/modules/auth/types'
	import colVue from '@/components/ui/grid/col.vue'
import { MetaInfo } from 'vue-meta'

	@Component({ components: { adDetailVue, adListVue, colVue } })
	export default class adPageVue extends Vue
	{
		@Meta metaInfo() { return this.meta }
		meta: MetaInfo | null = null

		get ad()
		{
			return (this.postId && this.$modules.Posts.posts[this.postId]) ?? null
		}
		postId: string | null = null

		get url()
		{
			return this.$route.params.ad
		}

		isActive = true
		activated() { return this.isActive = true }
		deactivated() { return this.isActive = false }

		@Watch('isActive')
		@Watch('url', { immediate: true }) async urlWatcher()
		{
			if (!this.isActive) return
			if (this.ad && this.ad.values.url === this.url) return

			this.postId = null
			this.meta = null

			try 
			{
				const ad = await this.$modules.Posts.rule('ad').filter(['url', '=', this.url]).first<any>()
				this.meta = { title: `"${ad.values.title}" ilan`, meta: [ { name: "description", content: ad.values.des } ] }
				await this.$modules.Posts.rule(`ad_${ad.values.type.value}`).first<any>(ad.id)
				this.postId = ad.id
			}
			catch (ex)
			{
				console.error(ex)
				this.$modules.Site.pageNotFound()
			}
		}
	}
