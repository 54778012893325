













































import { Post } from '@/store/modules/posts/types'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import textViewerVue from '../../textViewer.vue'
import responsiveVue from '../../responsive.vue'
import postVue from '../post.vue'

@Component({ components: { textViewerVue, responsiveVue, postVue } })
export default class articleDetailVue extends Vue
{
	@Prop() readonly post!: Post<any>
}
