

























































import postListVue from '@/components/ui/post/list.vue';
import postVue from '@/components/ui/post/post.vue';
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta';
import { Post } from '@/store/modules/posts/types';
import colVue from '@/components/ui/grid/col.vue';
import loaderVue from '@/components/ui/loader.vue';
import articleBlockVue from '@/components/ui/post/article/block.vue';
import parallaxVue from '@/components/ui/parallax.vue';
import smoothResizerVue from '@/components/ui/smoothResizer.vue';

@Component({ components: { postVue, colVue, loaderVue, articleBlockVue, parallaxVue, smoothResizerVue } })
export default class articlesCategoryPageVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	meta_title: string | null = null

	categoryId: string | null = null
	get category() { return this.categoryId ? this.$modules.Posts.posts[this.categoryId] : undefined }
	categories: Post<any>[] | null = null
	articleIds: string[] | null = null

	get categoryUrl()
	{
		return this.$route.params.category
	}

	get textHtml()
	{
		return this.category ? this.category.values.text : null
	}

	isActive = true
	activated() { return this.isActive = true }
	deactivated() { return this.isActive = false }

	mounted()
	{
		this.$modules.Posts.rule('article_category').get().then(v => this.categories = Object.values(v))
	}

	@Watch('isActive')
	@Watch('categoryUrl', { immediate: true }) async categoryUrlWatcher()
	{
		if (!this.isActive) return
		if (this.category && this.category.values.url === this.categoryUrl) return

		this.categoryId = null
		this.articleIds = null

		const category = await this.$modules.Posts.rule('article_category_details').filter(['url', '=', this.categoryUrl]).first<any>()
		this.meta_title = category.values.name
		this.articleIds = await this.$modules.Posts.rule('article').filter(['category', 'includes', category.id]).list()
		this.categoryId = category.id
	}
}
