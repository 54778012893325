import { FunctionHelper } from '@/libs/helpers/Function'
import { Api } from '@/plugins/api'
import { router } from '@/plugins/router'
import { store } from '@/store'
import { modules } from '@/store/classModules'
import { defineModule, VuexClassModule } from '@/modules/vuex-class-module'
import { Route } from 'vue-router'
import { User, UserProfile } from './types'

export const Auth = defineModule(new class extends VuexClassModule  
{
    get token()
    {
        return modules.LocalStorage.storage.token
    }
    user: User | null = null

    private iframe = document.createElement('iframe')

    start()
    {
        if (location.hostname !== 'www.1petci.com')
        {
            document.body.appendChild(this.iframe)
            this.iframe.style.opacity = '0'
            this.iframe.style.position = 'absolute'
            this.iframe.style.pointerEvents = 'none'
            this.iframe.src = 'https://www.1petci.com/token'

            window.addEventListener('message', (event) => 
            {
                switch (event.data.name)
                {
                    case 'auth':
                        this.setToken(event.data.content)
                        this.init()
                        break
                    case 'ready':
                        this.iframe.contentWindow?.postMessage({ name: 'get' }, '*')
                        break
                }
            })
        }
        else this.init()
    }

    init()
    {
        const locationQuery = new URLSearchParams(location.search)
        const queryToken = locationQuery.get('auth')
        if (queryToken) 
        {
            this.setToken(queryToken)
            locationQuery.delete('auth')
            location.search = locationQuery.toString()
        }

        function authCheck(route: Route)
        {
            if (route.meta.authOnly)
            {
                if (!Auth.token) return false

                if (typeof route.meta.authOnly === 'string' && Auth.user)
                {
                    if (Auth.user.profile.role.value === 'admin') return true
                    if (Auth.user.profile.role.value !== route.meta.authOnly) return false
                }
            }

            return true
        }

        store.watch(() => Auth.user, () =>
            !authCheck(router.currentRoute) && setTimeout(() => router.replace({ path: '/' }))
        )

        router.beforeEach((to, from, next) =>
        {
            modules.Site.resetPageNotFound()
            if (!authCheck(to)) return next({ name: "login", query: { redirect: to.path } })
            next(to.params)
        })

        store.watch(() => this.token, () => this.token ? this.auth() : this.user = null, { immediate: true })
    }

    setUser(user: User)
    {
        this.user = user
    }

    setToken(token: string)
    {
        modules.LocalStorage.setItem('token', token)
        this.iframe.contentWindow?.postMessage({ name: 'set', content: token }, '*')
    }

    clear()
    {
        modules.LocalStorage.removeItem('token')
        this.iframe.contentWindow?.postMessage({ name: 'set' }, '*')
    }

    async auth() // get profile user data
    {
        try
        {
            if (!this.token) return
            const response = (await Api.Do<{ id: string, mail: string }>('auth'))
            const postId = await this.getProfilePostId(response.id)
            this.setUser({
                id: response.id,
                mail: response.mail,
                get profile() { return modules.Posts.posts[postId]?.values as UserProfile ?? null },
                profilePostId: postId
            })
        }
        catch (err)
        {
            console.warn(err)
            await this.logout()
        }
    }

    async login(payload: { mail: string, password: string }) // get token
    {
        this.setToken((await Api.Do<{ token: string }>('login', payload)).token)
    }

    async register(payload: { mail: string, password: string, displayName: string })
    {
        await Api.Do('register', payload)
    }

    async forgotPassword(payload: { mail: string })
    {
        await Api.Do('forgot_password', payload)
    }

    async editUser(payload: { newMail: string, newPassword: string, oldPassword: string })
    {
        await Api.Do('edit_user', payload)
        await this.auth()
    }

    async logout()
    {
        this.clear()
    }

    getProfilePostId = FunctionHelper.defineCachedFunc(async (userId: string) =>
    {
        return (await modules.Posts.rule('user_profile').owner(userId).first<UserProfile>()).id
    })

    getProfilePostIdFromUrl = FunctionHelper.defineCachedFunc(async (url: string) =>
    {
        return (await modules.Posts.rule('user_profile').filter(['url', '=', url]).first<UserProfile>()).id
    })
})