





















































import { PostFilter, Post, PostOrder } from '@/store/modules/posts/types';
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import postFilterVue from '../filter.vue';
import postListVue from '../list.vue';
import adBlockVue from './block.vue';
import colVue from '../../grid/col.vue';
import loaderVue from '../../loader.vue';
import postVue from '../post.vue';
import stickyVue from '../../sticky.vue';
import { PromiseHelper } from '@/libs/helpers/Promise';

@Component({ components: { postListVue, postFilterVue, adBlockVue, colVue, loaderVue, postVue, stickyVue } })
export default class adListVue extends Vue
{
	@Prop() readonly showFilter!: boolean
	@Prop() readonly hideFilters!: string[]

	get hiddenFilters()
	{
		return this.hideFilters.concat(this.hideFiltersOverwrite)
	}

	hideFiltersOverwrite: string[] = []
	filtersOverwrite: PostFilter[] = []
	mounted()
	{
		this.$store.watch(() => this.$modules.Site.adDefaults, (adDefaults) =>
		{
			this.filtersOverwrite = []
			this.hideFiltersOverwrite = []
			if (adDefaults.type) 
			{
				const filter = ['type', '=', adDefaults.type]
				this.filtersOverwrite.push(filter)
				this.onFilterChange(filter)
				this.hideFiltersOverwrite.push('type')
			}
			const petType = adDefaults.petType
			if (petType) 
			{
				this.hideFiltersOverwrite.push('pet_type')
				this.filtersOverwrite.push(['pet_type', '=', petType])
			}
		}, { immediate: true });
		this.listFiltersWatcher()
	}

	@Prop() readonly owner?: string
	@Prop({ default: () => [] }) readonly filters!: PostFilter[]
	@Prop() readonly limit?: number

	filterMenu = false

	filterRuleName: string = 'ad'
	localFilters: PostFilter[] = []
	postIds: string[] | null = null
	lastPost: Post<any> | null = null
	firstPost: Post<any> | null = null

	isActive = true
	activated() { return this.isActive = true }
	deactivated() { return this.isActive = false }

	get listFilters()
	{
		return this.localFilters.concat(this.filters, this.filtersOverwrite)
	}

	onFilterChange(change: PostFilter)
	{
		if (change[0] === 'type' && change[1] === '=') this.filterRuleName = (change[2] !== undefined ? `ad_${change[2]}` : 'ad')
	}

	get listFiltersString() { return JSON.stringify(this.listFilters) }
	@Watch('listFiltersString', { /*immediate: true*/ }) async listFiltersWatcher()
	{
		this.postIds = null

		let queue = this.$modules.Posts.rule(this.filterRuleName).filter(...this.listFilters).limit(24)
		if (this.limit) queue = queue.limit(this.limit)
		if (this.owner) queue = queue.owner(this.owner)

		const ids = await queue.list();
		if (ids.length > 0) 
		{
			this.lastPost = await this.$modules.Posts.rule('ad').first(ids[ids.length - 1])
			this.firstPost = await this.$modules.Posts.rule('ad').first(ids[0])
		}
		await this.$modules.Posts.rule('ad').get(...ids)
		this.postIds = ids
	}

	async removePostId(postId: string)
	{
		if (!this.postIds) return
		this.postIds = this.postIds.filter((id) => id !== postId)
		this.lastPost = await this.$modules.Posts.rule('ad').first(this.postIds[this.postIds.length - 1])
		this.firstPost = await this.$modules.Posts.rule('ad').first(this.postIds[0])
	}

	loadMoreVisible = false
	@Watch('loadMoreVisible') async loadMoreVisibleWatcher()
	{
		if (this.loadMoreVisible) 
		{
			await this.loadMore()
			this.loadMoreVisible = false
		}
	}

	loadingMore = false
	async loadMore()
	{
		if (!this.lastPost) return
		if (this.loadingMore) return
		if (!this.postIds) return

		const maxLimit = 24
		let limit = (this.limit ? this.limit - this.postIds.length : maxLimit)
		if (limit <= 0) return
		if (limit > maxLimit) limit = maxLimit

		this.loadingMore = true

		const index = this.lastPost.creationIndex
		this.lastPost = null

		let queue = this.$modules.Posts.rule(this.filterRuleName).after(index).filter(...this.listFilters)
		if (this.owner) queue = queue.owner(this.owner)

		const newIds = await queue.limit(limit).list()
		if (newIds.length > 0) this.lastPost = await this.$modules.Posts.rule('ad').first(newIds[newIds.length - 1])

		await this.$modules.Posts.rule('ad').get(...newIds)
		this.postIds?.push(...newIds)

		this.loadingMore = false
	}

	loadingNew = false
	async loadNew()
	{
		//if (!this.firstPost) return
		if (this.loadingNew) return
		if (!this.postIds) return

		this.loadingNew = true

		const index = this.firstPost?.creationIndex

		let queue = this.$modules.Posts.rule(this.filterRuleName).order(PostOrder.asc).filter(...this.listFilters)
		if (index) queue = queue.after(index)
		if (this.owner) queue = queue.owner(this.owner)

		const newIds = (await queue.limit(12).list()).reverse()
		if (newIds.length > 0) this.firstPost = await this.$modules.Posts.rule('ad').first(newIds[0])

		await this.$modules.Posts.rule('ad').get(...newIds)
		this.postIds?.unshift(...newIds)
		if (this.limit) 
		{
			this.postIds = this.postIds.slice(0, this.limit)
			this.lastPost = await this.$modules.Posts.rule('ad').first(this.postIds[this.postIds.length - 1])
		}


		this.loadingNew = false
		this.loadNewButton = false;
	}

	loadNewButton = false
	loadNewInterval = -1
	onLoadNewIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver, isIntersecting: boolean)
	{
		if (isIntersecting)
		{
			if (this.loadNewInterval < 0) 
			{
				this.loadNewInterval = setInterval(async () =>
				{
					if (!this.isActive) return
					if (this.postIds === null) return
					if (!this.loadNewButton)
					{
						const index = this.firstPost?.creationIndex

						let queue = this.$modules.Posts.rule(this.filterRuleName).order(PostOrder.asc).filter(...this.listFilters)
						if (index) queue = queue.after(index)
						if (this.owner) queue = queue.owner(this.owner)

						const newIds = await queue.limit(1).list()
						if (newIds.length > 0) this.loadNewButton = true
					}
				}, 5000)
			}
		}
		else
		{
			if (this.loadNewInterval > 0) this.stopLoadNewInterval()
		}
	}

	stopLoadNewInterval()
	{
		clearInterval(this.loadNewInterval)
		this.loadNewInterval = -1
	}

	destroyed()
	{
		if (this.loadNewInterval > 0) this.stopLoadNewInterval()
	}
}
