







import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { VDialog } from 'vuetify/lib'

@Component
export default class routeDialogVue extends Vue
{
	@Prop() readonly paths!: Array<string>
	@Prop() readonly maxWidth!: string | number

	@Ref('dialog') readonly dialogRef!: typeof VDialog

	active = false
	visible = false

	@Watch('$route.name')
	@Watch('paths', { immediate: true }) pathsWatcher()
	{
		setTimeout(() =>
		{
			this.active = this.paths.includes(this.$route.name ?? '')
			if (this.active) this.$emit('input', this.$route.name)
		})
	}

	@Watch('active') activeWatcher()
	{
		if (this.active) this.visible = true;
		else setTimeout(() => this.visible = this.active, 750)

		if (!this.active)
		{
			const f = () =>
			{
				if (this.paths.includes(this.$route.name ?? ''))
				{
					if ((this.$router as any).routeBeforeSilent.matched.length > 0)
						this.$router.back()
					else this.$router.replace('/')
				}
			}
			f();
		}
	}
}
