
























































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import hoverVue from '../hover.vue'

type ValueType = string | File

@Component({ components: { hoverVue } })
export default class inputPictureVue extends Vue
{
	@Ref('fileDialogInput') readonly fileDialogInputRef!: HTMLInputElement

	@Prop() readonly prependIcon?: string
	@Prop() readonly label?: string
	@Prop() readonly multiple?: boolean
	@Prop() readonly loading?: boolean

	@Prop() readonly alternativeAdder?: () => Promise<ValueType[] | null>
	@Prop() readonly value?: ValueType | ValueType[] | null
	localValue: ValueType[] = []

	@Watch('value', { immediate: true }) valueWatcher() // can be null and can be an array or single
	{
		if (!this.value) return this.localValue = []
		if (Array.isArray(this.value)) this.localValue = this.value
		else this.localValue = [this.value]
	}
	@Watch('localValue') localValueWatcher() // cant be null and have to be array
	{
		this.$emit('input', this.multiple ? (this.localValue.length > 0 ? this.localValue : null) : this.localValue[0] ?? null)
	}


	add(files: ValueType[])
	{
		this.localValue = this.multiple ? ([] as ValueType[]).concat(this.localValue, files) : files.length > 0 ? [files[0]] : []
	}

	remove(index: number)
	{
		this.localValue = this.localValue.filter((v, i) => i !== index)
	}

	getFileUrl(file: File)
	{
		return URL.createObjectURL(file)
	}

	activateAdder()
	{
		if (this.alternativeAdder) this.alternativeAdder().then(v => v && this.add(v))
		else this.fileDialogInputRef.click()
	}

	pullFromInputAdder()
	{
		if (!this.fileDialogInputRef.files) return
		const files: File[] = []
		for (let i = 0; i < this.fileDialogInputRef.files.length; i++)
		{
			const file = this.fileDialogInputRef.files.item(i)
			if (!file) continue
			files.push(file)
		}
		this.add(files)
		this.fileDialogInputRef.value = ''
	}
}
