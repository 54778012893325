
















































import dynamicFieldGroupVue from '@/components/ui/post/dynamicGroup.vue'
import postListVue from '@/components/ui/post/list.vue'
import { Dictionary } from '@/libs/collections/Dictionary'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta'
import { Post, Rule, PostFilter } from '@/store/modules/posts/types'
import postFilterVue from '@/components/ui/post/filter.vue'
import colVue from '@/components/ui/grid/col.vue'
import postVue from '@/components/ui/post/post.vue'

@Component({ components: { dynamicFieldGroupVue, postListVue, postFilterVue, colVue, postVue } })
export default class adminPanelPageVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	meta_title: string | null = 'Admin Panel'

	rulename: string = this.$modules.LocalStorage.storage.panel_rulename ?? ''
	get rule() 
	{
		try 
		{
			this.$modules.LocalStorage.setItem('panel_rulename', this.rulename)
			return this.$modules.Posts.getRule(this.rulename)
		}
		catch { return undefined }
	}
	postId: string | null = null
	filter: PostFilter[] = []
	validated = false
	values: Dictionary<any> = {}
	postsIds: Array<string> = []

	@Watch('rule') ruleWatcher()
	{
		this.filter = []
		this.postId = null
	}

	@Watch('filter', { immediate: true, deep: true }) filterWatcher()
	{
		this.postsIds = []
		if (!this.rule) return;
		this.$modules.Posts.rule(this.rulename).filter(...this.filter).list().then(v => this.postsIds = v)
	}

	loading = 0
	async save()
	{
		this.loading++
		try
		{
			this.postId = (await this.$modules.Posts.set(this.rulename, this.values, this.postId ?? undefined)).id
		}
		catch (ex)
		{
			this.$modules.Snackbars.push(ex)
		}
		this.loading--
	}

	remove(id: string)
	{
		this.$modules.Posts.delete(this.rulename, id)
	}
}
