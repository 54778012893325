






























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Post } from '@/store/modules/posts/types'
import responsiveVue from '../../responsive.vue'
import colVue from '../../grid/col.vue'
import hoverVue from '../../hover.vue'

@Component({ components: { responsiveVue, colVue, hoverVue } })
export default class teamPersonBlockVue extends Vue
{
	@Prop() readonly teamPerson?: Post<any>

	hover = false

	get showText() { return this.hover }
}
