import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#000'
      },
      dark: {
        primary: '#f1f1'
      }
    }
  }
})