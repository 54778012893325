























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { UserProfile } from '@/store/modules/auth/types'

@Component
export default class profileAvatarVue extends Vue
{
	@Prop() readonly profile!: UserProfile
	@Prop() readonly size?: string | number

	shortName = ''
	@Watch('profile', { immediate: true }) profileWatcher()
	{
		if (!this.profile) return
		this.shortName = this.profile.displayName.substr(0, 2).toLocaleLowerCase()
	}
}
