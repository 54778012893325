import autoRoutes from 'vue-auto-routing'
import { RouteConfig } from 'vue-router'

// add silent routes here
const silentRoutes: RouteConfig[] = [
    {
        name: "login",
        path: "giris-yap"
    },
    {
        name: "register",
        path: "kayit-ol"
    },
    {
        name: "forgot-password",
        path: "sifremi-unuttum"
    },
    {
        name: "new-ad",
        path: "yeni-ilan",
        meta:
        {
            authOnly: true
        }
    },
    {
        name: "edit-ad",
        path: "ilan-duzenle/:adToEdit?",
        meta:
        {
            authOnly: true
        }
    }]

const homePage = () => import('@/pages/index.vue')
const pageNotFound = () => import('@/views/404.vue')

// add more routes here
const manualRoutes: RouteConfig[] = [
    {
        path: '*',
        component: pageNotFound
    }]




const _silentRoutes: RouteConfig[] = []

for (let route of silentRoutes)
{

    _silentRoutes.push(
        {
            name: route.name,
            path: route.path,
            component: homePage,
            meta: Object.assign({}, route.meta,
                {
                    silentRoute: true
                }),
            beforeEnter(to, from, next)
            {
                for (let key of Object.keys(from.params)) to.params[key] = from.params[key]
                next(to.params)
            }
        })
}

export const routes = autoRoutes.concat(_silentRoutes, manualRoutes)
