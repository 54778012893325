































import { Vue, Component, Prop } from 'vue-property-decorator'
import colVue from './grid/col.vue'

@Component({ components: { colVue } })
export default class quoteBlockVue extends Vue
{
	@Prop() readonly name!: string
	@Prop() readonly picture!: string
	@Prop() readonly title!: string
	@Prop() readonly text!: string
}
