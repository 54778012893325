import { FunctionHelper } from '@/libs/helpers/Function';
import { modules } from '@/store/classModules';
import { defineModule, VuexClassModule } from '@/modules/vuex-class-module';

export const Article = defineModule(new class extends VuexClassModule 
{
    GetArticleIdFromUrl = FunctionHelper.defineCachedFunc(async (articleUrl: string) =>
    {
        return (await modules.Posts.rule('article_details').filter(['url', '=', articleUrl]).first<any>()).id
    })
})