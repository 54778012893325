












import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class cookiePolicyVue extends Vue
{
	show = false

	mounted()
	{
		setTimeout(() => this.show = true, 7000)
	}

	get accepted()
	{
		return this.$modules.LocalStorage.storage.cookiePolicy
	}

	set accepted(value)
	{
		this.$modules.LocalStorage.setItem('cookiePolicy', value)
	}
}
