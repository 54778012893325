import { render, staticRenderFns } from "./avatar.vue?vue&type=template&id=572c44d7&"
import script from "./avatar.vue?vue&type=script&lang=ts&"
export * from "./avatar.vue?vue&type=script&lang=ts&"
import style0 from "./avatar.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VImg})
