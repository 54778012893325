















import { Vue, Component, Prop } from 'vue-property-decorator'
import { UserProfile } from '@/store/modules/auth/types'
import colVue from '../grid/col.vue'

@Component({ components: { colVue } })
export default class profileNameVue extends Vue
{
	@Prop() readonly profile!: UserProfile
	@Prop() readonly hideRole?: boolean
	@Prop() readonly textNoWrap?: boolean
}
