








import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { Post } from '@/store/modules/posts/types'
import { PromiseHelper } from '@/libs/helpers/Promise'

@Component
export default class postVue extends Vue
{
	@Prop({ required: true }) readonly rulename!: string
	@Prop({ required: true }) readonly postId!: string
	@Prop() readonly onlyWait?: boolean

	get post() { return this.$modules.Posts.posts[this.postId] }

	vShow = true
	placeholderHeight = 0

	@Watch('rulename')
	@Watch('postId', { immediate: true }) async postIdWatcher() 
	{
		this.vShow = true
		if (!this.onlyWait) await this.$modules.Posts.rule(this.rulename).first(this.postId)
		await PromiseHelper.Sleep()
		this.savePlaceHolder(Math.floor(this.$el.getBoundingClientRect().height))
	}

	savePlaceHolder(height: number) { if (height > 0) this.placeholderHeight = height }

	activated() { this.startObserver() }
	deactivated() { this.stopObserver(); this.vShow = false }

	observer: IntersectionObserver | null = null
	startObserver()
	{
		if (this.observer) return
		this.observer = new IntersectionObserver(this.onIntersect, {
			root: null,
			rootMargin: `${innerHeight / 2}px`,
			threshold: 0
		});
		this.observer.observe(this.$el)
	}

	stopObserver()
	{
		this.observer?.disconnect()
		this.observer = null
	}

	onIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver)
	{
		this.savePlaceHolder(Math.floor(entries[0].boundingClientRect.height))
		if (entries[0].isIntersecting)
		{
			this.vShow = true
			this.stopObserver()
		}
	}
}
