import Vue from 'vue'
import { routes } from './routes'
import { createRouterLayout } from 'vue-router-layout'

import { default as Router, Route } from 'vue-router'
import viewer from '@/components/common/router-view'

Vue.component('router-view', viewer)
Vue.use(Router)

const RouterLayout = createRouterLayout(layout =>
{
    return import('@/layouts/' + layout + '.vue')
})

interface ModifiedRouter extends Router
{
    routeBeforeSilent?: Route
    loadedRoute?: Route
}

export const router: ModifiedRouter = new Router(
    {
        mode: "history",
        routes: [
            {
                path: '/',
                component: RouterLayout,
                children: routes,
            }]
    })
console.log('test')
router.loadedRoute = router.currentRoute
router.beforeEach((to, from, next) =>
{
    console.log(from, to)
    if (to.meta.silentRoute)
    {
        if (!router.routeBeforeSilent) router.routeBeforeSilent = from
        router.loadedRoute = router.routeBeforeSilent.matched.length > 0 ? router.routeBeforeSilent : to
    }
    else
    {
        router.routeBeforeSilent = undefined
        router.loadedRoute = to
    }

    next(to.params)
})

if (window.navigator.vibrate) router.afterEach(() => window.navigator.vibrate(6))