








import { Dictionary } from '@/libs/collections/Dictionary'
import { Vue, Component, Prop } from 'vue-property-decorator'
import dynamicFieldsVue from './dynamic.vue'
import { PostFilter } from '@/store/modules/posts/types'

@Component({ components: { dynamicFieldsVue } })
export default class postFilterVue extends Vue
{
	@Prop() readonly ignoreInherits?: boolean
	@Prop({ required: true }) readonly rulename!: string
	get rule() { try { return this.$modules.Posts.getRule(this.rulename, this.ignoreInherits) } catch { } }

	get visibleFields()
	{
		return this.rule ? Object.fromEntries(Object.entries(this.rule.fields)
			.filter(([fieldName, field]) =>
				!this.hideFilters.includes(fieldName) &&
				field.permissions.list &&
				(
					field.type === 'select' ||
					field.type === 'search' ||
					(field.type === 'text' && !field.ui)
				))) : []
	}

	@Prop({ default: () => [] }) readonly hideFilters!: string[]
	filters: PostFilter[] = []
	values: Dictionary<any> = {}

	setValue(filterName: string, value: any)
	{
		if (!this.rule) return
		this.values[filterName] = value
		this.filters = []
		for (let [fieldName, value] of Object.entries(this.values))
		{
			if (Array.isArray(value)) array = value
			else var array = [value]

			for (const value of array)
			{
				if (value != undefined /* || null */)
				{
					let filter: PostFilter
					switch (this.rule.fields[fieldName].type)
					{
						case 'text':
							filter = [fieldName, 'like', `%${value}%`]
							break
						case 'search':
							filter = [fieldName, 'includes', value]
							break
						default:
							filter = [fieldName, '=', value]
							break
					}
					this.filters.push(filter)
					if (fieldName === filterName) this.$emit('change', filter)
				}
				else if (fieldName === filterName) this.$emit('change', [fieldName])
			}
		}
		this.$emit('input', this.filters)
	}
}
