import { modules } from '@/store/classModules';
import Vue from 'vue';
import VueMeta from 'vue-meta';

Vue.use(VueMeta)

const metaInfo = {
    titleTemplate: (titleChunk: any) =>
    {
        // If undefined or blank then we don't need the hyphen
        return titleChunk ? `${titleChunk} - ${modules.Site.title}` : modules.Site.title;
    }
}

export { metaInfo };
