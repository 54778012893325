



























import { UserProfile } from '@/store/modules/auth/types';
import { Vue, Component, Prop, Watch, ProvideReactive } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta'
import profileCardVue from '@/components/ui/profile/card.vue';
import { RawLocation } from 'vue-router';
import { Post } from '@/store/modules/posts/types';


@Component({ components: { profileCardVue } })
export default class profilePageVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	meta_title: string | null = null

	postId: string | null = null

	get url()
	{
		return this.$route.params.profile ?? (this.$modules.Auth.user && this.$modules.Auth.user.profile.url) ?? null
	}

	@ProvideReactive()
	get profile(): Post<UserProfile> | null { return this.postId ? this.$modules.Posts.posts[this.postId] : null }
	@ProvideReactive()
	get isOwner() { return this.$modules.Auth.user ? this.$modules.Auth.user.profile.url === this.url : null }
	@ProvideReactive()
	get isGod() { return this.$modules.Auth.user ? this.$modules.Auth.user.profile.role.value === 'admin' : null }

	isActive = true
	activated() { return this.isActive = true }
	deactivated() { return this.isActive = false }

	@Watch('isActive')
	@Watch('url', { immediate: true }) urlWatcher()
	{
		if (!this.isActive) return
		if (this.profile && this.profile.values.url === this.url) return

		this.postId = null
		this.meta_title = null
		if (this.url) (async () => 
		{
			try
			{
				this.postId = await this.$modules.Auth.getProfilePostIdFromUrl(this.url)
				if (this.profile) this.meta_title = this.profile.values.displayName
			}
			catch
			{
				this.$modules.Site.pageNotFound()
			}
		})()
	}

	get tabs()
	{
		const tabs: { title: string, route: RawLocation }[] = [
			{
				title: this.isOwner ? "İlanlarım" : "İlanlar",
				route:
				{
					name: 'profile',
					params: { profile: this.url },
				}
			},
			{
				title: this.isOwner ? "Hakkımda" : "Hakkında",
				route:
				{
					name: 'profile-about',
					params: { profile: this.url }
				}
			},
		]

		if (this.isOwner) tabs.push(
			{
				title: "Profil Ayarları",
				route:
				{
					name: 'my-edit'
				}
			},
			{
				title: "Güvenlik",
				route:
				{
					name: 'my-security'
				}
			})

		if (this.isOwner && this.isGod) tabs.push(
			{
				title: "Panel",
				route:
				{
					name: 'my-admin-panel'
				}
			})
		return tabs
	}
}
