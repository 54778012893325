




























import { Vue, Component, Ref } from 'vue-property-decorator'
import authFormVue from './components/ui/auth/form.vue'
import adFormVue from './components/ui/post/ad/form.vue'
import { RawLocation } from 'vue-router'
import routeDialogVue from './components/ui/routeDialog.vue'
import snackbarsVue from './components/common/snackbars.vue'
import siteModeChangerVue from './components/common/siteModeChanger.vue'
import { SiteModes } from './store/modules/site'

@Component({ components: { authFormVue, adFormVue, routeDialogVue, snackbarsVue, siteModeChangerVue } })
export default class App extends Vue
{
	@Ref('authDialog') readonly authDialogRef!: routeDialogVue
	activeAuthDialogPath = '' // change route dialong v-model as its activation bool and use route.path instead in here

	authPaths = ['login', 'register', 'forgot-password']
	adPaths = ['new-ad', 'edit-ad']

	popupActive = false
	mounted()
	{
		if (this.$modules.Site.mode === SiteModes.Exotic || this.$modules.Site.mode === SiteModes.Scottish)
		{
			fetch('https://cdn.1petci.com/uploads/oJSmRywQZz3cAUWF-1Kedi.com,%20all%20cats@2x.png?m=500')
			if (!this.$modules.LocalStorage.storage['1kedi.com popup'] ||
				parseInt(this.$modules.LocalStorage.storage['1kedi.com popup']) + 86400000 < new Date().getTime()
			) 
			{
				setTimeout(() => this.popupActive = true, 7000)
				this.$modules.LocalStorage.setItem("1kedi.com popup", new Date().getTime().toString())
			}
		}
	}

	get redirectPath(): RawLocation | null
	{
		if (typeof this.$route.query.redirect === 'string')
			return this.$route.query.redirect
		return null
	}

	onAuthDone()
	{
		if (this.redirectPath)
			this.$router.replace(this.redirectPath)
		else
			this.authDialogRef.active = false
	}
}
