










































































































































import { Post } from '@/store/modules/posts/types'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import responsiveVue from '../../responsive.vue'
import profileCardVue from '../../profile/card.vue'

@Component({ components: { responsiveVue, profileCardVue } })
export default class adDetailVue extends Vue
{
	@Prop({ required: true }) readonly ad?: Post<any>

	activeImg = 0

	get rule() { return this.ad && this.$modules.Posts.getRule(`ad_${this.ad.values.type.value}`, true) }

	get isOwner() { return this.$modules.Auth.user && this.ad && this.$modules.Auth.user.id == this.ad.owner }
	get isGod() { return this.$modules.Auth.user?.profile.role.value === 'admin' }

	ownerProfileId: string | null = null
	get ownerProfile()
	{
		return this.ownerProfileId ? this.$modules.Posts.posts[this.ownerProfileId]?.values ?? null : null
	}

	@Watch('ad', { immediate: true }) async adWatcher()
	{
		if (!this.ad) return
		const postId = (await this.$modules.Posts.rule('user_profile').owner(this.ad.owner).first()).id
		this.ownerProfileId = postId
	}

	async deleteAd()
	{
		this.ad && await this.$modules.Posts.delete('ad', this.ad.id)
	}

	editAd()
	{
		this.ad && this.$router.push({ name: 'edit-ad', params: { adToEdit: this.ad.id } })
	}
}
