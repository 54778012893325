import { ComponentOptions } from 'vue'
import { createDecorator, VueDecorator } from 'vue-class-component'
import { Vue } from 'vue-property-decorator'

const Meta: VueDecorator = createDecorator((options: ComponentOptions<Vue>, key: string) =>
{
    if (!options.methods) return
    options['metaInfo'] = options.methods[key]
})

export { Meta }
