



















import adListVue from '@/components/ui/post/ad/list.vue';
import { Meta } from '@/plugins/decorators/vue-meta';
import { Vue, Component, Prop, Watch, InjectReactive } from 'vue-property-decorator'
import profilePageVue from '@/pages/profil.vue';
import { UserProfile } from '@/store/modules/auth/types';
import { Post } from '@/store/modules/posts/types';

@Component({ components: { adListVue } })
export default class profilAdsPageVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	get meta_title()
	{
		if (this.profile) return `"${this.profile.values.displayName}" ilanlar`
	}

	isActive = true
	activated() { this.isActive = true }
	deactivated() { this.isActive = false }

	@InjectReactive() profile?: Post<UserProfile>
	@InjectReactive() isOwner?: boolean
	@InjectReactive() isGod?: boolean

	get listHiddenFilters()
	{
		return (this.isOwner || this.isGod) ? [] : ['listed', 'title']
	}
}
