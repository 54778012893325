


















































































































































































































import adListVue from '@/components/ui/post/ad/list.vue'
import postListVue from '@/components/ui/post/list.vue'
import postVue from '@/components/ui/post/post.vue'
import teamPersonBlockVue from '@/components/ui/post/team/block.vue'
import quoteBlockVue from '@/components/ui/quoteBlock.vue'
import sectionVue from '@/components/ui/section.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import responsiveVue from '@/components/ui/responsive.vue'
import parallaxVue from '@/components/ui/parallax.vue'
import adBlockVue from '@/components/ui/post/ad/block.vue'
import loaderVue from '@/components/ui/loader.vue'
import carouselItemsVue from '@/components/ui/post/carousel/items.vue'
import { SiteModes } from '@/store/modules/site'
import { Meta } from '@/plugins/decorators/vue-meta'
import { MetaInfo } from 'vue-meta'

@Component({ components: { adListVue, sectionVue, quoteBlockVue, postListVue, teamPersonBlockVue, postVue, responsiveVue, parallaxVue, adBlockVue, loaderVue, carouselItemsVue } })
export default class mainPageVue extends Vue
{
	@Meta metaInfo() { return this.meta }
	get meta(): MetaInfo
	{
		switch (this.$modules.Site.mode)
		{
			case SiteModes.Exotic:
				return {
					title: "Exotic Shorthair Yetiştirme ve Sahiplendirme Kulübü. Kedi sahiplenmek için ilanlar sayfasını incelemelisiniz",
					meta: [
						{
							name: "description",
							content: "Kedi ilanları, yüz binlerce bakım, sağlık, yetiştirme için en kapsamlı kedi sitesinde aradığınız her bilgi var!"
						}
					]
				}
			case SiteModes.Scottish:
				return {
					title: "Scottish Fold Yetiştirme ve Sahiplendirme Kulübü. Kedi sahiplenmek için ilanlar sayfasını incelemelisiniz",
					meta: [
						{
							name: "description",
							content: "Kedi ilanları, yüz binlerce bakım, sağlık, yetiştirme için en kapsamlı kedi sitesinde aradığınız her bilgi var!"
						}
					]
				}
			case SiteModes.Kedi:
				return {
					title: "Kedi Yetiştirme ve Sahiplendirme Kulübü. Kedi sahiplenmek için ilanlar sayfasını incelemelisinizı",
					meta: [
						{
							name: "description",
							content: "Kedi ilanları, yüz binlerce bakım, sağlık, yetiştirme için en kapsamlı kedi sitesinde aradığınız her bilgi var!"
						}
					]
				}
			case SiteModes.Petci:
			default:
				return {
					title: "Satılık, Ücretli Sahiplendirme ve Ücretsiz İlanlar",
					meta: [
						{
							name: "description",
							content: "Yüz binlerce bakım, sağlık, yetiştirme için en kapsamlı kedi sitesinde aradığınız her bilgi var!"
						}
					]
				}
		}

	}

	get carouselGalleryId()
	{
		switch (this.$modules.Site.mode)
		{
			case SiteModes.Exotic:
				return 'eijPVuZR'
			case SiteModes.Scottish:
				return '2dGR8roX'
			case SiteModes.Kedi:
				return 'P8sCcXt1'
			case SiteModes.Petci:
			default:
				return 'P8sCcXt1'
		}
	}
}
