















































import { Vue, Component, Prop } from 'vue-property-decorator'
import profileAvatarVue from './avatar.vue'
import profileNameVue from './name.vue'
import { UserProfile } from '@/store/modules/auth/types'

@Component({ components: { profileAvatarVue, profileNameVue } })
export default class profileBarVue extends Vue
{
	@Prop() readonly showName!: boolean
	@Prop() readonly profile?: UserProfile

	menu = false
}
