





























import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class snackbarsVue extends Vue
{
	get snackbars()
	{
		return this.$modules.Snackbars.snackbars
	}
}
