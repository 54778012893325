














import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import { Meta } from '@/plugins/decorators/vue-meta'
import profilePageVue from '@/pages/profil.vue'
import loaderVue from '@/components/ui/loader.vue'
import { UserProfile } from '@/store/modules/auth/types'
import { Post } from '@/store/modules/posts/types'

@Component({ components: { loaderVue } })
export default class profileAboutVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	get meta_title()
	{
		if (this.profile) return `"${this.profile.values.displayName}" hakkında`
	}

	isActive = true
	activated() { this.isActive = true }
	deactivated() { this.isActive = false }

	@InjectReactive() profile?: Post<UserProfile>
}
