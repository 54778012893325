

























import { PromiseHelper } from '@/libs/helpers/Promise'
import { Vue, Component, Ref } from 'vue-property-decorator'

@Component
export default class smoothResizerVue extends Vue
{
	@Ref('container') container!: HTMLElement
	@Ref('content') content!: HTMLElement

	mounted()
	{
		(async () =>
		{
			while (true)
			{
				await PromiseHelper.Sleep(100)
				if (!this.content) continue
				const rect = this.content?.getBoundingClientRect()
				if (!rect) continue
				this.container.style.height = `${rect.height}px`
			}
		})()
	}
}
