



















import { Vue, Component, Prop } from 'vue-property-decorator'
import articlesCategoryPageVue from './kategori/_category.vue';
import { Post } from '@/store/modules/posts/types';
import parallaxVue from '@/components/ui/parallax.vue';
import articleCategoryBlockVue from '@/components/ui/post/articleCategory/block.vue';
import postVue from '@/components/ui/post/post.vue';
import loaderVue from '@/components/ui/loader.vue';
import { Meta } from '@/plugins/decorators/vue-meta';

@Component({ components: { articleCategoryBlockVue, postVue, loaderVue } })
export default class articlesPageVue extends Vue
{
	@Meta metaInfo() { return this.meta_title && { title: this.meta_title } }
	meta_title: string | null = 'Ansiklopedi'

	categoriesIds: string[] | null = null

	async mounted()
	{
		this.categoriesIds = Object.values(await this.$modules.Posts.rule('article_category').list())
	}
}
