import { ActionTree, GetterTree, MutationTree, Store } from "vuex";

export class VuexClassModule
{
    start(): void { }
}

export function registerModules(vuexStore: Store<any>, modules: { [moduleName: string]: unknown })
{
    for (const [moduleName, instance] of Object.entries(modules))
        vuexStore.registerModule(moduleName, vuexModuleFromClass(vuexStore, moduleName, instance))
    Object.freeze(modules)

    const vuexClassModule = {
        install: (Vue: any, options: any) =>
        {
            Vue.prototype.$modules = modules
        }
    }

    for (const [name, module] of Object.entries(modules) as any as [string, VuexClassModule][])
    {
        console.log(`Starting Vuex ClassModule ${name}`)
        if (module.start) module.start()
    }

    return { vuexClassModule }
}

//Vue.use(modulesPlugin);

export function defineModule<T extends VuexClassModule>(moduleClass: T)
{
    return Object.assign(Object.getPrototypeOf(moduleClass), moduleClass) as T
}

function vuexModuleFromClass(store: Store<any>, moduleName: string, classObject: any)
{
    const states: { [key: string]: any } = {}
    const getters: GetterTree<any, any> = {}
    const mutations: MutationTree<any> = {}
    const actions: ActionTree<any, any> = {}

    const propertyDescriptors = Object.getOwnPropertyDescriptors(classObject)

    for (const [descriptorName, descriptor] of Object.entries(propertyDescriptors))
    {
        if (descriptor.get) // get property
        {
            Object.defineProperty(classObject, `_getproperty_${descriptorName}`, descriptor)
            Object.defineProperty(classObject, descriptorName, { get: () => store.getters[`${moduleName}/${descriptorName}`] })

            getters[descriptorName] = (states) => classObject[`_getproperty_${descriptorName}`]
        }
        else if (descriptor.set) // set property
        {
            Object.defineProperty(classObject, `_setproperty_${descriptorName}`, descriptor)
            Object.defineProperty(classObject, descriptorName, { set: (value) => store.commit(`${moduleName}/${descriptorName}`, value) })

            mutations[descriptorName] = (states, payload) => classObject[`_setproperty_${descriptorName}`] = payload
        }
        else
        {
            switch (typeof descriptor.value ?? '')
            {
                case 'function':
                    Object.defineProperty(classObject, `_function_${descriptorName}`, descriptor)
                    classObject[`_function_${descriptorName}`] = classObject[descriptorName]

                    const isAsync = false //descriptor.value.constructor.name === 'AsyncFunction'

                    if (isAsync)
                    {
                        actions[descriptorName] = (context, ...parameters) => 
                        {
                            return classObject[`_function_${descriptorName}`](...parameters)
                        }
                        classObject[descriptorName] = (...parameters: any[]) => 
                        {
                            return store.dispatch(`${moduleName}/${descriptorName}`, parameters)
                        }
                    }
                    else
                    {
                        //let result: any
                        mutations[descriptorName] = (states, ...parameters: any[]) => 
                        {
                            //result = classObject[`_function_${descriptorName}`](...parameters)
                        }
                        classObject[descriptorName] = (...parameters: any[]) => 
                        {
                            const result = classObject[`_function_${descriptorName}`](...parameters)
                            store.commit(`${moduleName}/${descriptorName}`, parameters)
                            return result
                        }
                    }

                    switch (descriptorName)
                    {
                        case 'start':
                            (() =>
                            {
                                let moduleStarted = false
                                mutations[descriptorName] = (states, ...parameters: any[]) => 
                                {
                                    if (moduleStarted) throw new Error(`Vuex ClassModule ${moduleName} already started`)
                                    moduleStarted = true
                                    classObject[`_function_${descriptorName}`]()
                                }
                                classObject[descriptorName] = (...parameters: any[]) =>
                                {
                                    store.commit(`${moduleName}/${descriptorName}`, parameters)
                                }
                            })()
                            break
                    }
                    break
                default: // variable
                    Object.defineProperty(classObject, descriptorName,
                        {
                            set: (value) => 
                            {
                                store.state[moduleName][descriptorName] = value
                            },
                            get: () => store.state[moduleName][descriptorName]
                        }
                    )

                    states[descriptorName] = descriptor.value
                    break
            }
        }
    }

    const module = {
        namespaced: true,
        state() { return states },
        actions: actions,
        getters: getters,
        mutations: mutations
    }

    console.log(`Registered Vuex ClassModule ${moduleName}`, module, classObject)

    Object.freeze(classObject)

    return module
}