






import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class hoverVue extends Vue
{
	@Prop({ default: false }) readonly value!: boolean	
	localValue: boolean = this.value

	@Watch('value') valueWatcher()
	{
		this.localValue = this.value
	}

	@Watch('localValue') localValueWatcher()
	{
		this.$emit('input', this.localValue)
	}

	deactivated() { this.localValue = false }
	activated() { this.localValue = false }
}
