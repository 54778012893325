// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
console.log('1')
import 'reflect-metadata'
import 'vue-class-component/hooks' // import hooks type to enable auto-complete

import Vue from 'vue'
import { router } from '@/plugins/router'
import { store } from '@/store'

import '@/registerServiceWorker'
import '@/registerVueFilters'
import '@/plugins/sanitize'

import { metaInfo } from '@/plugins/meta'
import { vuetify } from '@/plugins/vuetify'

require('@/assets/css/responsive.css')
require('@/assets/css/custom-transitions.css')
require('@/assets/css/vuetify-overwrites.css')

import App from '@/App.vue'

new Vue(
    {
        router,
        store,
        vuetify,
        metaInfo,
        render: h => h(App)
    }).$mount('#app')
    console.log('2')