import { vuetify } from "@/plugins/vuetify"
import { store } from "@/store"
import { defineModule, VuexClassModule } from "@/modules/vuex-class-module"

export enum SiteModes
{
    Kedi = '1kedi',
    Petci = '1petci',
    Exotic = 'exoticshorthair',
    Scottish = 'scottishfold'
}
export const Site = defineModule(new class extends VuexClassModule
{
    mode: SiteModes | null = null
    darkMode = vuetify.framework.theme.dark
    siteColor = vuetify.framework.theme.currentTheme.primary
    title = ''
    logo = ''
    adDefaults = {
        type: null as string | null,
        petType: null as string | null
    }

    start()
    {
        switch (location.hostname)
        {
            case 'exoticshorthair.com.tr':
            case 'www.exoticshorthair.com.tr':
            case 'cattery.com.tr':
            case 'www.cattery.com.tr':
                this.mode = SiteModes.Exotic
                break
            case 'www.1petci.com':
            case '1petci.com':
                this.mode = SiteModes.Petci
                break
            case 'scottishfold.com.tr':
            case 'www.scottishfold.com.tr':
                this.mode = SiteModes.Scottish
                break
            case '1kedi.com':
            case 'www.1kedi.com':
                this.mode = SiteModes.Kedi
                break
            default:
                this.mode = SiteModes.Petci
                break
        }

        store.watch(() => this.mode, this.onSiteModeChange)
        store.watch(() => this.darkMode, this.onSiteThemeChange)
        store.watch(() => this.siteColor, this.onSiteThemeChange)
        this.onSiteModeChange()
        this.onSiteThemeChange()
    }

    onSiteModeChange()
    {
        switch (this.mode)
        {
            case SiteModes.Exotic:
                this.title = "ExoticShorthair"
                this.darkMode = false
                this.siteColor = this.darkMode ? '#838F52' : '#a6cc40'
                this.logo = require('@/assets/svg/logo/ExoticShorthair.svg')
                this.adDefaults = { type: 'cat', petType: 'exotic' }
                break
            case SiteModes.Scottish:
                this.title = 'ScottishFold'
                this.darkMode = false
                this.siteColor = this.darkMode ? '#183A52' : '#3E96D2'
                this.logo = require('@/assets/svg/logo/ScottishFold.svg')
                this.adDefaults = { type: 'cat', petType: 'scottish_fold' }
                break
            case SiteModes.Kedi:
                this.title = "1kedi"
                this.darkMode = false
                this.siteColor = '#6C63FF'
                this.logo = require('@/assets/svg/logo/1Kedi.svg')
                this.adDefaults = { type: 'cat', petType: null }
                break
            case SiteModes.Petci:
                this.title = "1Petci"
                this.darkMode = false
                this.siteColor = '#6C63FF'
                this.logo = require('@/assets/svg/logo/1Petci.svg')
                this.adDefaults = { type: null, petType: null }
                break
            
        }
    }

    onSiteThemeChange()
    {
        vuetify.framework.theme.dark = this.darkMode
        vuetify.framework.theme.currentTheme.primary = this.siteColor
        vuetify.framework.theme.currentTheme.secondary = this.siteColor
        vuetify.framework.theme.currentTheme.accent = this.siteColor
    }

    _pageNotFound = false
    get hostname() { return location.hostname }
    get isPageFound() { return !this._pageNotFound }

    pageNotFound()
    {
        this._pageNotFound = true
    }

    resetPageNotFound()
    {
        this._pageNotFound = false
    }
})
