









































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from '@/libs/collections/Dictionary'

type CodeOption = { text: string, options: Dictionary<string>, optional: boolean, divide: string }

@Component
export default class codeNumberVue extends Vue
{
	@Prop({ required: true }) readonly pattern!: [string | CodeOption]
	@Prop() readonly prependIcon?: string
	@Prop() readonly label?: string
	@Prop() readonly loading?: boolean
	@Prop() readonly value?: string[]
	@Prop() readonly optional?: boolean

	get patternEntries()
	{
		return (this.pattern.filter((part) => typeof part === 'object') as CodeOption[])
			.map((part) => ({
				optional: part.optional,
				rules: part.optional || (this.optional && !this.asString) ? [] : [(v: string) => !!v || 'Bu alan zorunlu!'],
				text: `${part.text}${part.optional ? ' (istege bağlı)' : ''}`,
				options: Object.entries(part.options)
					.map(([value, text]) => ({ value, text: `${value} (${text})` }))
			}))
	}

	get textFieldRules()
	{
		return this.optional ? [] : [(v: string) => !!v || 'Bu alan zorunlu!']
	}

	get asString() 
	{
		const parts: string[] = []
		let partIndex = 0
		for (let i = 0; i < this.pattern.length; i++) 
		{
			const part = this.pattern[i]
			if (typeof part === 'string') 
			{
				parts.push(part)
				continue
			}

			const value = this.localValue[partIndex]
			if (value) parts.push(value)

			partIndex++
		}
		return parts.join('').replace(/\s\s+/g, ' ').trim()
	}

	@Watch('localValue') emitLocalValue() { this.$emit('input', this.localValue) }
	localValue: string[] = []

	@Watch('value', { immediate: true }) valueWatcher()
	{
		if (Array.isArray(this.value))
			this.localValue = this.value
	}
}
