










import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from '@/libs/collections/Dictionary'
import dynamicFieldsVue from './dynamic.vue'
import { PostFilter, PostOrder } from '@/store/modules/posts/types'
import loaderVue from '../loader.vue'

@Component({ components: { dynamicFieldsVue, loaderVue } })
export default class postListVue extends Vue
{
	@Prop({ required: true }) readonly rulename!: string
	get rule() { return this.$modules.Posts.getRule(this.rulename) }

	@Prop() readonly filters!: PostFilter[]
	@Prop() readonly owner!: string
	@Prop() readonly order!: PostOrder
	@Prop() readonly limit!: number

	@Prop() readonly align!: string
	@Prop() readonly justify!: string
	@Prop() readonly cols!: string | number
	@Prop() readonly sm!: string | number
	@Prop() readonly md!: string | number
	@Prop() readonly lg!: string | number
	@Prop() readonly xl!: string | number


	ids: string[] | null = null

	@Watch('filters', { immediate: true }) propsWatcher(value: PostFilter[], oldValue: PostFilter[]) 
	{
		if (JSON.stringify(value) == JSON.stringify(oldValue)) return
		this.reload()
	}

	mounted()
	{
		this.reload()
	}

	reload()
	{
		this.ids = null
		this.$modules.Posts.list(this.rulename,
			{
				filters: this.filters,
				limit: this.limit,
				order: this.order,
				owner: this.owner
			}).then(v => this.ids = v)
	}

	loadMore()
	{

	}
}
