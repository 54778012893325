import { ObjectHelper } from '@/libs/helpers/Object';
import { Snackbar } from '@/store/modules/snackbar/types';
import { Dictionary } from '../libs/collections/Dictionary';
import { modules } from '../store/classModules';

const requestTime = 25 // bundled requests time

function AddToFormData(key: string, value: any, formData: FormData, obj: Dictionary<any> = {}, formDataKey = key)
{
    if (value === undefined) return

    if (value === null) obj[key] = null
    else switch (value.constructor.name)
    {
        case 'Array':
        case 'Object':
            const entries = Object.entries(value)
            const childObj = obj[key] = (value.constructor.name === 'Array' ? [] : {})
            for (const [itemKey, itemValue] of entries) AddToFormData(itemKey, itemValue, formData, childObj, `${formDataKey}[${itemKey}]`)
            break
        case 'File':
            formData.append(formDataKey, value)
            break
        case 'Date':
            obj[key] = (value as Date).getTime()
            break
        case 'Number':
        case 'String':
        case 'Boolean':
            obj[key] = value
            break
    }

    if (formDataKey === key) formData.append('j', JSON.stringify(obj))
}

interface Request
{
    resolve: Function
    reject: Function
    do: string
    params: Dictionary<any>
}

let queuedRequests: Request[]

let timeouting = false

function Queue(request: Request)
{
    if (timeouting) queuedRequests.push(request)
    else
    {
        timeouting = true
        queuedRequests = [request]
        setTimeout(() =>
        {
            timeouting = false;
            Execute(queuedRequests)
        }, requestTime)
    }
}

function Execute(queuedRequests: Request[])
{
    const formData = new FormData()
    if (modules.Auth.token) formData.append('auth', modules.Auth.token)
    AddToFormData('r', queuedRequests.map(r => ({ d: r.do, p: r.params })), formData)

    function finish(responses: any[] = [])
    {
        for (let i = 0; i < queuedRequests.length; i++)
        {
            const queuedRequest = queuedRequests[i]
            const response = responses[i]
            if (response) 
            {
                if (response.$error) queuedRequest.reject(new Error(response.$error))
                else queuedRequest.resolve(response)
            }
            else queuedRequest.reject(new Error('Missing response'))
            console.log('api request done', queuedRequest)
        }
    }

    const req = new XMLHttpRequest()
    req.open('POST', 'https://api.1petci.com')
    req.onload = () =>
    {
        if (req.status == 200)
        {
            try
            {
                finish(JSON.parse(req.responseText))
            }
            catch
            {
                finish()
                throw new Error(`Parsing Error. Text: ${req.responseText}`)
            }
        }
        else { finish(); throw new Error(`Connection Error: ${req.status}`) }
        if (snackbar) setTimeout(snackbar.remove, 5000)
    }
    let snackbar: Snackbar | null = null
    const startedAt = performance.now()
    req.upload.onprogress = (event) =>
    {
        if (startedAt + 1500 > performance.now() || event.total < .1 * 1024 * 1204) return
        if (!snackbar) snackbar = modules.Snackbars.push('', -1)

        const total = (event.total / 1024 / 1024).toFixed(2)
        const loaded = (event.loaded / 1024 / 1204).toFixed(2)

        if (event.loaded !== event.total) snackbar.message.text = `Yükleniyor... ${loaded}/${total}MB`
        else snackbar.message.text = `Yüklendi ${total}MB`
    }
    req.send(formData)
}

class Api
{
    async Do<T>(name: string, values: Dictionary<any> = {}): Promise<T>
    {
        return await new Promise<T>((resolve, reject) => Queue({ do: name, params: ObjectHelper.mergeDeep({}, values), resolve, reject }));
    }
}

const instance = new Api()
export { instance as Api };
