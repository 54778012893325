






























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Post } from '@/store/modules/posts/types'
import responsiveVue from '../../responsive.vue'
import postVue from '../post.vue'
import profileNameVue from '../../profile/name.vue'
import imgPrefetchVue from '../../imgPrefetch.vue'
import hoverVue from '../../hover.vue'

@Component({ components: { responsiveVue, postVue, profileNameVue, imgPrefetchVue, hoverVue } })
export default class adBlockVue extends Vue
{
	@Prop({ required: true }) readonly ad!: Post<any>
	@Prop() readonly hideMoreButton?: boolean

	ownerPostId: string | null = null
	get owner() { return this.ownerPostId ? this.$modules.Posts.posts[this.ownerPostId] : null }
	@Watch('ad', { immediate: true }) async postIdWatcher()
	{
		if (this.ad) this.ownerPostId = await this.$modules.Auth.getProfilePostId(this.ad.owner)
	}

	get isOwner() { return this.$modules.Auth.user && this.ad && this.ad.owner == this.$modules.Auth.user.id }
	get isGod() { return this.$modules.Auth.user?.profile.role.value === 'admin' }

	get text() { return this.ad ? this.$sanitize(this.ad.values.des).replaceAll(new RegExp('\\[(.*?)\\]', 'g'), '') : undefined }

	deleteAd()
	{
		this.$modules.Posts.delete('ad', this.ad.id)
	}

	setListed(listed: boolean)
	{
		this.$modules.Posts.set('ad', { listed: listed ? 't' : 'f' }, this.ad.id).catch(this.$modules.Snackbars.push)
	}
}
