




























import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { Random } from '@/libs/random'

export enum Fit
{
	Contain = 'contain',
	Cover = 'cover',
	Fill = 'fill'
}

@Component
export default class imgPrefetchVue extends Vue
{
	@Prop() readonly src?: string
	@Prop() readonly width?: string
	@Prop() readonly height?: string
	@Prop() readonly alt?: string
	@Prop() readonly fit?: Fit

	get showSlot() { return this.$slots && Object.keys(this.$slots).length > 0 }

	get dNone() { return !this.intersected || !this.fetched }

	background = this.$vuetify.theme.currentTheme.primary

	fetched = false
	@Watch('src', { immediate: true }) srcWatcher()
	{
		if (!this.src) return
		this.fetched = false
		this.intersected = false
		this.stopObserver()

		this.background = '#0101' //Random.randomColor()

		const tempImg = document.createElement('img')
		tempImg.src = this.src
		fetch(tempImg.src).then(() =>
		{
			this.fetched = true
			this.startObserver()
		})
	}

	activated() { this.startObserver() }
	deactivated() { this.stopObserver() }

	observer: IntersectionObserver | null = null
	intersected = false
	startObserver()
	{
		if (this.observer) return
		this.observer = new IntersectionObserver(this.onIntersect, {
			root: null,
			rootMargin: `${innerHeight / 2}px`,
			threshold: 0
		});
		this.observer.observe(this.$el)
	}

	stopObserver()
	{
		this.observer?.disconnect()
		this.observer = null
	}

	onIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver)
	{
		if (entries[0].isIntersecting)
		{
			this.intersected = true
			this.stopObserver()
		}
	}
}
