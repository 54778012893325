



























































































import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { Post } from '@/store/modules/posts/types'
import { Dictionary } from '@/libs/collections/Dictionary'
import inputPictureVue from '../../inputs/picture.vue'
import postVue from '../post.vue'
import loaderVue from '../../loader.vue'
import colVue from '../../grid/col.vue'
import galleryAlbumFormVue from './form.vue'
import smoothResizerVue from '../../smoothResizer.vue'

type ValueType = string | File

@Component({ components: { inputPictureVue, postVue, loaderVue, colVue, galleryAlbumFormVue, smoothResizerVue } })
export default class galleryVue extends Vue
{
	@Prop() readonly hide?: boolean
	@Prop() readonly multiple?: boolean
	@Prop() readonly prependIcon?: string
	@Prop() readonly label?: string

	@PropSync('dialog', { default: false }) dialogActive!: boolean

	albums: string[] | null = null

	tabIndex = 0
	selected: string[] = []

	@Watch('dialogActive', { immediate: true }) async dialogActiveWatcher()
	{
		if (!this.dialogActive) 
		{
			this.selected = []
			// this.albums = null
		}
		else
		{
			this.refreshGallery()
		}
	}

	formActive = false
	formPostId: string | null = null

	activateForm(postId?: string)
	{
		this.formPostId = postId ?? null
		this.formActive = true
	}

	deactivateForm()
	{
		this.formActive = false
		this.formPostId = null
	}

	done()
	{
		this.$emit('input', this.selected)
	}

	toggleSelect(picture: string)
	{
		if (this.selected.includes(picture)) this.selected = this.selected.filter(v => v !== picture)
		else this.selected.push(picture)
	}

	refreshing = false
	async refreshGallery()
	{
		if (!this.$modules.Auth.user) return
		if (this.refreshing) return
		this.refreshing = true
		this.albums = await this.$modules.Posts.rule('user_gallery').owner(this.$modules.Auth.user.id).list()
		this.refreshing = false
	}

	async deleteAlbum(postId: string)
	{
		await this.$modules.Posts.delete('user_gallery', postId)
		this.refreshGallery()
	}

	toDate(miliseconds: number)
	{
		return new Date(miliseconds).toDateString()
	}
}
