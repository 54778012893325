











import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'

@Component
export default class loaderVue extends Vue
{
	@Prop() readonly loading!: boolean
	@Prop() readonly cacheHeight?: boolean

	@Ref('wrapper') readonly wrapperRef!: HTMLDivElement

	isLoading = this.loading

	@Watch('loading') loadingWatcher(n: boolean, o: boolean)
	{
		if (this.cacheHeight)
		{
			if (!o && n) this.wrapperRef.style.height = `${this.wrapperRef.getBoundingClientRect().height}px`
			else this.wrapperRef.style.height = ''
		}

		this.isLoading = n
	}
}
