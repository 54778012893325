
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Post } from '@/store/modules/posts/types'
import parallaxVue from '../../parallax.vue'
import responsiveVue from '../../responsive.vue'
import hoverVue from '../../hover.vue'

@Component({ components: { parallaxVue, responsiveVue, hoverVue } })
export default class articleCategoryBlockVue extends Vue
{
	@Prop() readonly category!: Post<any>
	backgroundColor = `rgba(${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 100)}, ${Math.floor(Math.random() * 100)}, 0.5)`
	textHtml: string | null = null

	hover = false
	get showText() { return this.hover }

	@Watch('category', { immediate: true }) categoryWatcher()
	{
		if (this.category && this.category.values.text) 
		{
			const html = this.$sanitize(this.category.values.text)
			if (html) this.textHtml = html
		}
	}
}
