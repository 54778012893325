
const helper = {
    /**
     * Deep merge two objects.
     * @param target
     * @param ...sources
     */
    mergeDeep(target: any, ...sources: any[]): any
    {
        if (typeof target !== 'object') throw new Error('mergeDeep needs target to be an object')

        if (!sources.length) return target;
        const source = sources.shift();
        if (typeof source !== 'object') throw new Error('mergeDeep needs source to be an object')

        const sourceObjects: any[] = []
        const targetObjects: any[] = []

        for (const key in source)
        {
            let value = source[key]
            if (value === undefined) continue
            if (typeof value === 'object' && value !== null)
            {
                switch (value.constructor.name)
                {
                    case 'Date':
                        target[key] = new Date(value)
                        break
                    case 'File':
                        target[key] = value
                        break
                    default:
                        let index = sourceObjects.indexOf(value)
                        if (index < 0)
                        {
                            index = sourceObjects.length
                            sourceObjects.push(value)
                            targetObjects.push(Array.isArray(value) ? [] : {})
                        }
                        if (target[key] === undefined) target[key] = targetObjects[index]
                        this.mergeDeep(target[key], value)
                        break
                }
            }
            else target[key] = value
        }

        return this.mergeDeep(target, ...sources);
    }
};
export { helper as ObjectHelper };
